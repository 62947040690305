import React, { useEffect, useMemo } from 'react';
import {
  useAlert,
  useLoadMedia,
  useMedia,
  useRouter,
  useStoreState,
} from '../../hooks';
import { DataLoading, VideoRibbon } from '../common';
import VideoDetail from './VideoDetail';
import './index.scss';
import CustomAlert from '../common/CustomAlert';
import { AlertTypeEnum } from '../../customTypes';
import MediaAlert from '../common/MediaAlert';
import MediaSnackBar from '../common/MediaSnackBar';

const VideoById: React.FC = () => {
  const { getParams, navigateTo } = useRouter();
  const { loadVideobyId } = useLoadMedia();
  const { closeAlert } = useAlert();

  const {
    auth: { userCurrency },
    media: {
      currentVideo,
      ribbonVideos,
      isCurrentVideoLoading,
      hasCurrentVideoErrorOccured,
    },
    alert: { type: alertType, isOpen: isAlertOpen },
  } = useStoreState();
  const { selectMedia, addMediaToCart, addMediaToLibrary } = useMedia();

  const relatedVideos = useMemo(() => {
    if (currentVideo && ribbonVideos) {
      return ribbonVideos.filter((video) => video.id !== currentVideo.id);
    } else {
      return [];
    }
  }, [ribbonVideos, currentVideo]);

  useEffect(() => {
    const id = getParams().id;
    loadVideobyId(+id);
  }, [getParams().id]);

  useEffect(() => {
    if (hasCurrentVideoErrorOccured) {
      navigateTo('/');
    }
  }, [hasCurrentVideoErrorOccured]);

  if (isCurrentVideoLoading || !currentVideo) {
    return <DataLoading />;
  } else {
    return (
      <div className="videoById">
        <VideoDetail
          onAddToLibrary={addMediaToLibrary}
          onAddToCart={addMediaToCart}
          video={currentVideo}
          userCurrency={userCurrency}
        />
        {relatedVideos && relatedVideos.length > 0 && (
          <div className="videoById__ribbonHeader">
            <h3>Related Videos</h3>
            <hr className="videoById__divider" />
          </div>
        )}

        {relatedVideos && relatedVideos.length > 0 && (
          <div className="videoById__ribbon">
            <VideoRibbon
              onDeleteFromLibrary={() => {}}
              onAddToLibrary={addMediaToLibrary}
              onAddToCart={addMediaToCart}
              onVideoSelect={selectMedia}
              video={relatedVideos}
              currency={userCurrency}
            />
          </div>
        )}
        <MediaAlert />
        <MediaSnackBar />
      </div>
    );
  }
};

export default VideoById;
