import React from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import './billingDetails.scss';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { ICheckoutAddressInput, IShippingUserInput } from '../../customTypes';
import { useStoreState } from '../../hooks';

interface IBillingDetails {
  userInput: IShippingUserInput;
  isSameAsShipping: boolean;
  onToggleSameAsShipping: (e: React.ChangeEvent<HTMLInputElement>) => void;
  addressInput: ICheckoutAddressInput;
  onUserInputChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onAddressInputChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
}

const BillingDetails: React.FC<IBillingDetails> = ({
  isSameAsShipping,
  userInput,
  addressInput,
  onUserInputChange,
  onAddressInputChange,
  onToggleSameAsShipping,
}) => {
  const {
    checkout: { isSSLPurchaseInProgress, isStripePurchaseInProgress },
  } = useStoreState();
  return (
    <Card className="billingDetails">
      <Card.Body>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check
            checked={isSameAsShipping}
            onChange={onToggleSameAsShipping}
            type="checkbox"
            label="Same as shipping"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="billingDetails__label">Email*</Form.Label>
          <Form.Control
            name="email"
            value={userInput.email}
            onChange={onUserInputChange}
            required
            type="email"
            placeholder="Last Name"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="billingDetails__label">Address 1*</Form.Label>
          <Form.Control
            name="billingAddress1"
            value={addressInput.billingAddress1}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="Address 1"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="billingDetails__label">Address 2</Form.Label>
          <Form.Control
            name="billingAddress2"
            value={addressInput.billingAddress2}
            onChange={onAddressInputChange}
            type="text"
            placeholder="Address 2"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="billingDetails__label">City*</Form.Label>
          <Form.Control
            name="billingCity"
            value={addressInput.billingCity}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="City"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="billingDetails__label">State*</Form.Label>
          <Form.Control
            name="billingState"
            value={addressInput.billingState}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="State"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="billingDetails__label">PostCode*</Form.Label>
          <Form.Control
            name="billinPostCode"
            value={addressInput.billingPostCode}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="PostCode"
          />
        </Form.Group>
        {(isSSLPurchaseInProgress || isStripePurchaseInProgress) && (
          <Spinner animation="border" variant="primary" />
        )}
        {!isSSLPurchaseInProgress && !isStripePurchaseInProgress && (
          <Button className="billingDetails__button" type="submit">
            <AttachMoneyIcon className="billingDetails__icon" />
            Go to Payment
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default BillingDetails;
