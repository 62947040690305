import React from 'react';
import { useHistory, useParams } from 'react-router';
import { IBook, ICartItem } from '../models';
import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';
import { useRouter } from './useRouter';

export const useCheckout = () => {
  const { setCheckoutPrice } = useStoreActions();
  const { navigateTo } = useRouter();
  const {
    auth: { token, userVat },
  } = useStoreState();

  const checkout = (
    totalPrice: number | null,
    totalDiscount: number | null,
    totalVat: number | null
  ) => {
    setCheckoutPrice(totalPrice, totalDiscount, totalVat);

    if (totalPrice === null || totalDiscount === null || totalVat === null) {
      return;
    }
    navigateTo('/shipping');
  };

  return { checkout };
};
