import React from 'react';
import { useAlert, useAuth, useStoreState } from '../../hooks';
import { useRouteMatch } from 'react-router-dom';
import AppBar from './AppBar';
import Footer from './Footer';
import './index.scss';
import { AlertTypeEnum } from '../../customTypes';
import CustomAlert from '../common/CustomAlert';

const Layout: React.FC = ({ children }) => {
  const {
    auth: { isAuthenticated, user },
    alert: { type: alertType, isOpen: isAlertOpen },
  } = useStoreState();
  const { closeAlert } = useAlert();
  const { signOut } = useAuth();

  const isLoginRoute = useRouteMatch('/login');
  const isRegistrationRoute = useRouteMatch('/register');
  const isForgetPasswordRoute = useRouteMatch('/forget-password');
  const isPaymentSuccessRoute = useRouteMatch('/payment-success');
  const isPaymentFailedRoute = useRouteMatch('/payment-failed');
  return (
    <div className="layout">
      <div className="layout__header">
        {!isPaymentSuccessRoute && !isPaymentFailedRoute && (
          <AppBar isAuthenticated={isAuthenticated} user={user} />
        )}

        <div>{children}</div>
      </div>
      {!isLoginRoute &&
        !isRegistrationRoute &&
        !isForgetPasswordRoute &&
        !isPaymentSuccessRoute &&
        !isPaymentFailedRoute && <Footer />}

      {alertType === AlertTypeEnum.Logout && (
        <CustomAlert
          title="Logout"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          noButtonText="Not now"
          yesButtonText="Logout"
          onYesButtonPress={() => {
            signOut();
            closeAlert();
          }}
        />
      )}
    </div>
  );
};

export default Layout;
