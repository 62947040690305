import React, { useEffect, useState, useMemo } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import {
  AlertTypeEnum,
  ICheckoutAddressInput,
  IShippingUserInput,
} from '../../customTypes';
import { useAlert, useCheckout, useRouter, useStoreState } from '../../hooks';
import { usePayment } from '../../hooks/usePayment';
import BackendAlert from '../common/BackendAlert';
import CustomAlert from '../common/CustomAlert';
import BillingDetails from './BillingDetails';
import './index.scss';
import ShippingDetails from './ShippingDetails';

const Shipping: React.FC = () => {
  const {
    auth: { user },
    checkout: {
      totalPrice,
      totalVat,
      totalDiscount,
      sslGatewayUrl,
      stripeSessionId,
    },
    alert: { type: alertType, isOpen: isAlertOpen },
  } = useStoreState();
  const { navigateTo } = useRouter();
  const [mobile, setMobile] = useState('');
  const [userInput, setUserInput] = useState<IShippingUserInput>({
    title: user && user.title ? user.title : 'Mr',
    firstName: user && user.firstName ? user.firstName : '',
    lastName: user && user.lastName ? user.lastName : '',
    email: user && user.email ? user.email : '',
  });
  const [addressInput, setAddressInput] = useState<ICheckoutAddressInput>({
    shippingAddress1: user && user.address1 ? user.address1 : '',
    shippingAddress2: user && user.address2 ? user.address2 : '',
    shippingCity: user && user.city ? user.city : '',
    shippingState: user && user.state ? user.state : '',
    shippingPostCode: user && user.postCode ? user.postCode : '',
    shippingCountryCode:
      user && user.registeredCountry ? user.registeredCountry : 'BGD',
    deliveryZoneCode: user && user.registeredCountry === 'BGD' ? 'BDD' : 'GBR',
    billingAddress1: '',
    billingAddress2: '',
    billingCity: '',
    billingState: '',
    billingPostCode: '',
  });
  const [isSameAsShipping, setIsSameAsShipping] = useState(false);
  const {
    payWithSSL,
    payWithStripe,
    resetSSLGateway,
    redirectToStripeCheckout,
    resetStripeSession,
  } = usePayment();

  const handleUserInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setUserInput({
      ...userInput,
      [e.target.name]: e.target.value,
    });
  };

  const { closeAlert } = useAlert();

  const handleAddressInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setAddressInput({
      ...addressInput,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSameAsShipping(e.target.checked);
  };

  const handleGoToPayment = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!totalPrice) {
      return;
    }

    if (user && user.registeredCountry === 'BGD') {
      payWithSSL(totalPrice, userInput, addressInput, mobile);
      return;
    }

    if (user && user.registeredCountry !== 'BGD') {
      payWithStripe(totalPrice, userInput, addressInput, mobile);
      return;
    }
  };

  useEffect(() => {
    if (isSameAsShipping) {
      setAddressInput({
        ...addressInput,
        billingAddress1: addressInput.shippingAddress1,
        billingAddress2: addressInput.shippingAddress2,
        billingCity: addressInput.shippingCity,
        billingState: addressInput.shippingState,
        billingPostCode: addressInput.shippingPostCode,
      });
    } else {
      setAddressInput({
        ...addressInput,
        billingAddress1: '',
        billingAddress2: '',
        billingCity: '',
        billingState: '',
        billingPostCode: '',
      });
    }
  }, [isSameAsShipping]);

  useEffect(() => {
    if (totalPrice === null || totalDiscount === null || totalVat === null) {
      navigateTo('/');
    }
  }, [totalPrice, totalDiscount, totalVat]);

  useEffect(() => {
    if (sslGatewayUrl) {
      window.location.href = sslGatewayUrl;
    }

    return () => {
      resetSSLGateway();
    };
  }, [sslGatewayUrl]);

  useEffect(() => {
    if (stripeSessionId) {
      redirectToStripeCheckout(stripeSessionId);
    }

    return () => {
      resetStripeSession();
    };
  }, [stripeSessionId]);

  return (
    <Container className="shipping">
      <Row>
        <Col>
          <Form onSubmit={handleGoToPayment}>
            <h4>Shipping Details</h4>
            <hr />
            <ShippingDetails
              onUserInputChange={handleUserInputChange}
              onAddressInputChange={handleAddressInputChange}
              userInput={userInput}
              addressInput={addressInput}
              mobile={mobile}
              onMobileChange={setMobile}
            />
            <h4>Billing Details</h4>
            <hr />
            <BillingDetails
              onUserInputChange={handleUserInputChange}
              onAddressInputChange={handleAddressInputChange}
              userInput={userInput}
              addressInput={addressInput}
              isSameAsShipping={isSameAsShipping}
              onToggleSameAsShipping={handleCheckBox}
            />
          </Form>
        </Col>
      </Row>
      {alertType === AlertTypeEnum.ValidMobileNumberRequired && (
        <CustomAlert
          title="Valid Mobile Required"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          noButtonText="Ok"
          onYesButtonPress={() => {}}
        />
      )}
      <BackendAlert />
    </Container>
  );
};

export default Shipping;
