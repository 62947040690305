export const constants = {
  primaryColor: '#054bc8',
  accentColor: '#fcb247',
  lightBackgroudColor: '#efefef',
  tokenStorageKey: 'cholishnu-web-token',
  booksLimitPerPage: 12,
  ordersLimitPerPage: 10,
  authorsLimitPerPage: 10,
  searchLimitPerPage: 30,
  longDateFormat: 'DD-MM-YYYY HH:mm:ss',
  shortDateFormat: 'DD/MM/YYYY',
  orderDateFormat: 'h:mm a, DD MMM YYYY',
};
