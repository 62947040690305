export const error = {
  formatMessage: (err: any) => {
    console.log(err.response ? err.response.data : err.message);
    console.log(err.message);
    if (err.response?.status === 500) {
      return err.response
        ? 'There is a problem with connecting to server'
        : 'An Unexpected error occured. Please Try again';
    } else if (err.response?.status === 404) {
      return err.response
        ? 'We could not find what you are looking for'
        : 'An Unexpected error occured. Please Try again';
    } else {
      return err.response
        ? Object.values(err.response.data)[0]
        : 'An Unexpected error occured. Please Try again';
    }
  },
};
