import React from 'react';
import {
  IEditProfileFormInput,
  ILoginFormData,
  IRegisterFormData,
  RegistrationInputEnum,
} from '../customTypes';
import { IRegistrationInputData } from '../customTypes';
import { date, password, mobile as phoneUtil } from '../utility';
import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';

export const useAuth = () => {
  const {
    setTokenFromStorage,
    getUser,
    login,
    logout,
    forgetPassword,
    setRegistrationdError,
    register,
    getCountryInfo,
    getUserCountryInfo,
    setUserVat,
    toggleChangePasswordModal,
    setChangePasswordError,
    changePassword,
    resetPasswordChangeSuccess,
    updateProfile,
    getDeliveryZones,
  } = useStoreActions();

  const {
    auth: { token, user },
  } = useStoreState();

  const checkForToken = () => {
    setTokenFromStorage();
  };

  const loadUserData = (token: string) => {
    getUser(token);
  };

  const loadCountryInfo = () => {
    getCountryInfo();
  };

  const loadDeliveryZones = () => {
    getDeliveryZones();
  };

  const loadUserVat = (vat: number) => {
    setUserVat(vat);
  };

  const loadUserCountryInfo = (
    currentCountry: string,
    registeredCountry: string
  ) => {
    getUserCountryInfo(currentCountry, registeredCountry);
  };

  const createAccount = (formData: IRegistrationInputData, mobile: string) => {
    if (!password.validate(formData.password)) {
      setRegistrationdError(
        'Password must contain at least 8 characters, an uppercase, a lowercase and a number',
        RegistrationInputEnum.Password
      );

      return;
    }

    if (!password.match(formData.password, formData.confirmPassword)) {
      setRegistrationdError(
        'Password did not match',
        RegistrationInputEnum.ConfirmPassword
      );

      return;
    }

    if (!date.verifyAge(new Date(formData.dob))) {
      setRegistrationdError(
        'You must be at least 13 years old',
        RegistrationInputEnum.Dob
      );

      return;
    }
    if (!phoneUtil.validate(mobile)) {
      setRegistrationdError(
        'You must enter a valid phone number',
        RegistrationInputEnum.Mobile
      );

      return;
    }

    const registrationData: IRegisterFormData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: formData.password,
      dob: new Date(formData.dob),
      mobile,
      country: phoneUtil.getCountryCode(mobile),
    };

    register(registrationData);
  };

  const signIn = (userdata: ILoginFormData) => {
    login(userdata);
  };

  const signOut = () => {
    logout();
  };

  const forgetPasswordEmailSend = (email: string) => {
    forgetPassword(email);
  };

  const resetRegistrationError = () => {
    setRegistrationdError(null);
  };

  const togglePasswordModal = (isOpen: boolean) => {
    toggleChangePasswordModal(isOpen);
  };

  const setPasswordError = (error: string | null) => {
    setChangePasswordError(error);
  };

  const updatePassword = (
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) => {
    if (!password.validate(newPassword)) {
      setPasswordError(
        'Password must be at least 8 characters and have a number, uppercase and lowercase'
      );
      return;
    }

    if (!password.match(newPassword, confirmNewPassword)) {
      setPasswordError('New Password and confirm password did not match');
      return;
    }

    changePassword(oldPassword, newPassword, token);
  };

  const resetPasswordSuccess = () => {
    resetPasswordChangeSuccess();
  };

  const editProfile = (userInfo: IEditProfileFormInput, mobile: string) => {
    if (!user) {
      return;
    }
    updateProfile(token, userInfo, mobile, user.dob, user.currentCountry);
  };

  return {
    loadUserData,
    checkForToken,
    signIn,
    signOut,
    forgetPasswordEmailSend,
    resetRegistrationError,
    createAccount,
    loadCountryInfo,
    loadUserCountryInfo,
    loadUserVat,
    resetPasswordSuccess,
    togglePasswordModal,
    updatePassword,
    setPasswordError,
    editProfile,
    loadDeliveryZones,
  };
};
