import React, { useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { ICartItem } from '../../models';
import { price } from '../../utility';
import './cartSummary.scss';

interface ICartSummaryProps {
  userVat: number;
  cartItems: ICartItem[];
  onCheckout: (
    totalPrice: number,
    totalDiscount: number,
    totalVat: number
  ) => void;
}

const CartSummary: React.FC<ICartSummaryProps> = ({
  cartItems,
  userVat,
  onCheckout,
}) => {
  const allPrices = useMemo(() => {
    return cartItems.map((item) => item.totalPrice);
  }, [cartItems]);

  const allDiscounts = useMemo(() => {
    return cartItems.map((item) => item.discount);
  }, [cartItems]);

  const subTotal = useMemo(() => {
    return allPrices.reduce(
      (accumulator, currentItem) => accumulator + currentItem,
      0
    );
  }, [allPrices]);

  const totalDiscount = useMemo(() => {
    return allDiscounts.reduce(
      (accumulator, currentItem) => accumulator + currentItem,
      0
    );
  }, [allDiscounts]);

  const totalVat = useMemo(() => {
    return (userVat / 100) * (subTotal - totalDiscount);
  }, [subTotal, userVat, totalDiscount]);

  const netTotal = useMemo(() => {
    return subTotal + totalVat - totalDiscount;
  }, [subTotal, totalVat, totalDiscount]);

  const currency = useMemo(() => {
    return cartItems[0].currency;
  }, [cartItems]);

  const hasDiscount = useMemo(() => {
    return totalDiscount > 0;
  }, [totalDiscount]);
  return (
    <Card>
      <Card.Header>Cart Summary</Card.Header>
      <Card.Body>
        <Card.Title>
          Subtotal:{' '}
          <span className="cartSummary__price">
            {price.format(currency, subTotal)}
          </span>
        </Card.Title>
        {hasDiscount && (
          <Card.Text>
            Discount:{' '}
            <span className="cartSummary__price discount">
              - {price.format(currency, totalDiscount)}
            </span>
          </Card.Text>
        )}
        <Card.Text>
          Vat ({userVat}%):{' '}
          <span className="cartSummary__price">
            {price.format(currency, totalVat)}
          </span>
        </Card.Text>
        <hr />
        <Card.Title className="cartSummary__title">
          Total: <span>{price.format(currency, netTotal)}</span>
        </Card.Title>
        <hr />
        <Button
          onClick={() => onCheckout(netTotal, totalDiscount, totalVat)}
          className="cartSummary__button"
        >
          Checkout
        </Button>
      </Card.Body>
    </Card>
  );
};

export default CartSummary;
