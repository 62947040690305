export const storage = {
  saveItem: (key: string, item: any) => {
    localStorage.setItem(key, item);
  },

  getItem: (key: string) => {
    return localStorage.getItem(key);
  },

  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
};
