export enum ActionTypes {
  LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
  REGISTER_IN_PROGRESS = 'REGISTER_IN_PROGRESS',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILED = 'REGISTER_FAILED',
  SET_REGISTRATION_ERROR = 'SET_REGISTRATION_ERROR',
  LOGOUT = 'LOGOUT',
  SET_TOKEN = 'SET_TOKEN',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_FAILED = 'GET_USER_FAILED',
  FORGET_EMAIL_SENDING_IN_PROGRESS = 'FORGET_EMAIL_SENDING_IN_PROGRESS',
  FORGET_EMAIL_SENDING_SUCCESS = 'FORGET_EMAIL_SENDING_SUCCESS',
  FORGET_EMAIL_SENDING_FAILED = 'FORGET_EMAIL_SENDING_FAILED',
  GET_RIBBON_BOOKS_IN_PROGRESS = 'GET_RIBBON_BOOKS_IN_PROGRESS',
  GET_RIBBON_BOOKS_SUCCESS = 'GET_RIBBON_BOOKS_SUCCESSFUL',
  GET_RIBBON_BOOKS_FAILED = 'GET_RIBBON_BOOKS_FAILED',
  GET_RIBBON_AUDIO_IN_PROGRESS = 'GET_RIBBON_AUDIO_IN_PROGRESS',
  GET_RIBBON_AUDIO_SUCCESS = 'GET_RIBBON_AUDIO_SUCCESSFUL',
  GET_RIBBON_AUDIO_FAILED = 'GET_RIBBON_AUDIO_FAILED',
  GET_RIBBON_VIDEO_IN_PROGRESS = 'GET_RIBBON_VIDEO_IN_PROGRESS',
  GET_RIBBON_VIDEO_SUCCESS = 'GET_RIBBON_VIDEO_SUCCESSFUL',
  GET_RIBBON_VIDEO_FAILED = 'GET_RIBBON_VIDEO_FAILED',
  GET_FEATURES_IN_PROGRESS = 'GET_FEATURES_IN_PROGRESS',
  GET_FEATURES_SUCCESS = 'GET_FEATURES_SUCCESSFUL',
  GET_FEATURES_FAILED = 'GET_FEATURES_FAILED',
  GET_BOOK_BY_ID_IN_PROGRESS = 'GET_BOOK_BY_ID_IN_PROGRESS',
  GET_BOOK_BY_ID_SUCCESS = 'GET_BOOK_BY_ID_SUCCESS',
  GET_BOOK_BY_ID_FAILED = 'GET_BOOK_BY_ID_FAILED',
  GET_VIDEO_BY_ID_IN_PROGRESS = 'GET_VIDEO_BY_ID_IN_PROGRESS',
  GET_VIDEO_BY_ID_SUCCESS = 'GET_VIDEO_BY_ID_SUCCESS',
  GET_VIDEO_BY_ID_FAILED = 'GET_VIDEO_BY_ID_FAILED',
  GET_AUDIO_BY_ID_IN_PROGRESS = 'GET_AUDIO_BY_ID_IN_PROGRESS',
  GET_AUDIO_BY_ID_SUCCESS = 'GET_AUDIO_BY_ID_SUCCESS',
  GET_AUDIO_BY_ID_FAILED = 'GET_AUDIO_BY_ID_FAILED',
  GET_BOOKS_BY_SAME_AUTHOR = 'GET_BOOKS_BY_SAME_AUTHOR',
  GET_BOOKS_BY_SAME_PUBLISHER = 'GET_BOOKS_BY_SAME_PUBLISHER',
  GET_BOOKS_BY_SAME_AUTHOR_FAILED = 'GET_BOOKS_BY_SAME_AUTHOR_FAILED',
  GET_BOOKS_BY_SAME_PUBLISHER_FAILED = 'GET_BOOKS_BY_SAME_PUBLISHER_FAILED',
  GET_ORDERS_IN_PROGRESS = 'GET_ORDERS_IN_PROGRESS',
  GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILED = 'GET_ORDERS_FAILED',
  SET_ORDER_CURRENT_PAGE = 'SET_ORDER_CURRENT_PAGE',
  TOGGLE_CHANGE_PASSWORD_MODAL = 'TOGGLE_CHANGE_PASSWORD_MODAL',
  CHANGE_PASSWORD_IN_PROGRESS = 'CHANGE_PASSWORD_IN_PROGRESS',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED',
  SET_CHANGE_PASSWORD_ERROR = 'SET_CHANGE_PASSWORD_ERROR',
  RESET_CHANGE_PASSWORD_SUCCESS = 'RESET_CHANGE_PASSWORD_SUCCESS',
  SEARCH_IN_PROGRESS = 'SEARCH_IN_PROGRESS',
  SEARCH_SUCCESS = 'SEARCH_SUCCESS',
  SEARCH_FAILED = 'SEARCH_FAILED',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  SET_SEARCH_PAGE_NUMBER = 'SET_SEARCH_PAGE_NUMBER',
  GET_COUNTRY_INFO = 'GET_COUNTRY_INFO',
  GET_USER_COUNTRY_INFO = 'GET_USER_COUNTRY_INFO',
  SET_USER_VAT = 'SET_USER_VAT',
  GET_LIBRARY_ITEM_IN_PROGRESS = 'GET_LIBRARY_ITEM_IN_PROGRESS',
  GET_LIBRARY_ITEM_SUCCESS = 'GET_LIBRARY_ITEM_SUCCESS',
  GET_LIBRARY_ITEM_FAILED = 'GET_LIBRARY_ITEM_FAILED',
  GET_ORDER_HISTORY_ITEM_IN_PROGRESS = 'GET_ORDER_HISTORY_ITEM_IN_PROGRESS',
  GET_ORDER_HISTORY_ITEM_SUCCESS = 'GET_ORDER_HISTORY_ITEM_SUCCESS',
  GET_ORDER_HISTORY_ITEM_FAILED = 'GET_ORDER_HISTORY_ITEM_FAILED',
  GET_ORDER_DETAIL_BY_ID_IN_PROGRESS = 'GET_ORDER_DETAIL_BY_ID_IN_PROGRESS',
  GET_ORDER_DETAIL_BY_ID_SUCCESS = 'GET_ORDER_DETAIL_BY_ID_SUCCESS',
  GET_ORDER_DETAIL_BY_ID_FAILED = 'GET_ORDER_DETAIL_BY_ID_FAILED',
  GET_CART_DATA_IN_PROGRESS = 'GET_CART_DATA_IN_PROGRESS',
  GET_CART_DATA_SUCCESS = 'GET_CART_DATA_SUCCESS',
  GET_CART_DATA_FAILED = 'GET_CART_DATA_FAILED',
  INCREASE_CART_ITEM_QUANTITY = 'INCREASE_CART_ITEM_QUANTITY',
  DECREASE_CART_ITEM_QUANTITY = 'DECREASE_CART_ITEM_QUANTITY',
  DELETE_CART_ITEM = 'DELETE_CART_ITEM',
  REMOVE_ALL_CART_ITEMS = 'REMOVE_ALL_CART_ITEMS',
  ADD_ITEM_TO_CART_IN_PROGRESS = 'ADD_ITEM_TO_CART_IN_PROGRESS',
  ADD_ITEM_TO_CART_SUCCESS = 'ADD_ITEM_TO_CART_SUCCESS',
  ADD_ITEM_TO_CART_FAILED = 'ADD_ITEM_TO_CART_FAILED',
  RESET_ADD_TO_CART_SUCCESS = 'RESET_ADD_TO_CART_SUCCESS',
  ADD_ITEM_TO_LIBRARY_IN_PROGRESS = 'ADD_ITEM_TO_LIBRARY_IN_PROGRESS',
  ADD_ITEM_TO_LIBRARY_SUCCESS = 'ADD_ITEM_TO_LIBRARY_SUCCESS',
  ADD_ITEM_TO_LIBRARY_FAILED = 'ADD_ITEM_TO_LIBRARY_FAILED',
  RESET_ADD_TO_LIBRARY_SUCCESS = 'RESET_ADD_TO_LIBRARY_SUCCESS',
  SET_CHECKOUT_PRICE = 'SET_CHECKOUT_PRICE',
  DELETE_LIBRARY_ITEM_IN_PROGRESS = 'DELETE_LIBRARY_ITEM_IN_PROGRESS',
  DELETE_LIBRARY_ITEM_SUCCESS = 'DELETE_LIBRARY_ITEM_SUCCESS',
  DELETE_LIBRARY_ITEM_FAILED = 'DELETE_LIBRARY_ITEM_FAILED',
  PURCHASE_SSL_IN_PROGRESS = 'PURCHASE_SSL_IN_PROGRESS',
  PURCHASE_SSL_SUCCESS = 'PURCHASE_SSL_SUCCESS',
  PURCHASE_SSL_FAILED = 'PURCHASE_SSL_FAILED',
  PURCHASE_STRIPE_IN_PROGRESS = 'PURCHASE_STRIPE_IN_PROGRESS',
  PURCHASE_STRIPE_SUCCESS = 'PURCHASE_STRIPE_SUCCESS',
  PURCHASE_STRIPE_FAILED = 'PURCHASE_STRIPE_FAILED',
  SET_SSL_GATEWAY = 'SET_SSL_GATEWAY',
  SET_STRIPE_SESSION = 'SET_STRIPE_SESSION',
  SET_ALERT = 'SET_ALERT',
  RESET_ALERT = 'RESET_ALERT',
  UPDATE_PROFILE_IN_PROGRESS = 'UPDATE_PROFILE_IN_PROGRESS',
  UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED',
  RESET_UPDATE_PROFILE_SUCCESS = 'RESET_UPDATE_PROFILE_SUCCESS',
  GET_DELIVERY_ZONE = 'GET_DELIVERY_ZONE',
}
