import { Dispatch } from 'redux';
import { IAuthorItem, IGenreItem, IPlaylistItem } from '../../customTypes';
import { IAudio, IBook, IFeatureItem, IVideo } from '../../models';
import { envVariables, http } from '../../utility';
import { ActionTypes } from '../types';
import { IMediaAction } from './interface';

export const getRibbonBooks =
  () => async (dispatch: Dispatch<IMediaAction>) => {
    dispatch({
      type: ActionTypes.GET_RIBBON_BOOKS_IN_PROGRESS,
    });

    const path = `${envVariables.apiBaseUrl}/books/getallribbons`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_RIBBON_BOOKS_SUCCESS,
        payload: data.map((book: any): IBook => {
          return {
            id: book.id,
            contentType: book.contentTypeName,
            title: book.bookTitle,
            author: book.authorItems.map((author: any): IAuthorItem => {
              return {
                id: author.id,
                name: author.name,
                role: author.authorRole,
              };
            }),
            coverArt: `${envVariables.awsBooksBaseUrl}/${book.coverImage}`,
            genre: book.genreItems.map((genre: any): IGenreItem => {
              return {
                id: genre.id,
                name: genre.englishName ? genre.englishName : genre.description,
              };
            }),
            publisher: book.publisherName,
            publisherLogo: `${envVariables.publisherLogoBaseUrl}/${book.publisherLogo}`,
            description: book.shortDescription,
            sourceUrl: book.contentUrl,
            sampleUrl: book.sampleUrl,
            media: 'Book',
            priceInBDT: book.priceBdt,
            priceInGBP: book.priceGbp,
            priceInUSD: book.priceUsd,
            token: book.point,
            isEContent: book.isEContent,
            isPaid: book.isPaid,
            discountInBDT: book.discountBdt,
            discountInGBP: book.discountGbp,
            discountInUSD: book.discountUsd,
          };
        }),
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.GET_RIBBON_BOOKS_FAILED,
      });
    }
  };

export const getRibbonAudio =
  () => async (dispatch: Dispatch<IMediaAction>) => {
    dispatch({
      type: ActionTypes.GET_RIBBON_AUDIO_IN_PROGRESS,
    });

    const path = `${envVariables.apiBaseUrl}/audios/getallribbons`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_RIBBON_AUDIO_SUCCESS,
        payload: data.map((audio: any): IAudio => {
          return {
            id: audio.id,
            contentType: audio.contentTypeName,
            title: audio.audioTitle,
            artist: audio.authorItems.map((artist: any): IAuthorItem => {
              return {
                id: artist.id,
                name: artist.name,
                role: artist.authorRole,
              };
            }),
            coverArt: `${envVariables.awsAudioBaseUrl}/${audio.coverImage}`,
            genre: audio.genreItems.map((genre: any): IGenreItem => {
              return {
                id: genre.id,
                name: genre.englishName ? genre.englishName : genre.description,
              };
            }),
            publisher: audio.publisherName,
            description: audio.shortDescription,
            playlist: audio.albumPostCastItems.map(
              (item: any): IPlaylistItem => {
                return {
                  id: item.id,
                  title: item.title,
                  sourceUrl: item.contentUrl,
                  artists: item.authorItems.map((artist: any): IAuthorItem => {
                    return {
                      id: artist.id,
                      name: artist.name,
                      role: artist.authorRole,
                    };
                  }),
                };
              }
            ),
            media: 'Audio',
            priceInBDT: audio.priceBdt,
            priceInGBP: audio.priceGbp,
            priceInUSD: audio.priceUsd,
            token: audio.point,
            isEContent: audio.isEContent,
            isPaid: audio.isPaid,
            discountInBDT: audio.discountBdt,
            discountInGBP: audio.discountGbp,
            discountInUSD: audio.discountUsd,
          };
        }),
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.GET_RIBBON_AUDIO_FAILED,
      });
    }
  };

export const getRibbonVideo =
  () => async (dispatch: Dispatch<IMediaAction>) => {
    dispatch({
      type: ActionTypes.GET_RIBBON_VIDEO_IN_PROGRESS,
    });

    const path = `${envVariables.apiBaseUrl}/videos/getallribbons`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_RIBBON_VIDEO_SUCCESS,
        payload: data.map((video: any): IVideo => {
          return {
            id: video.id,
            contentType: video.contentTypeName,
            title: video.title,
            artist: video.authorItems.map((author: any): IAuthorItem => {
              return {
                id: author.id,
                name: author.name,
                role: author.authorRole,
              };
            }),
            coverArt: `${envVariables.awsVideoBaseUrl}/${video.coverImage}`,
            genre: video.genreItems.map((genre: any): IGenreItem => {
              return {
                id: genre.id,
                name: genre.englishName ? genre.englishName : genre.description,
              };
            }),
            publisher: video.publisherName,
            description: video.shortDescription,
            sourceUrl: video.contentUrl,
            sampleUrl: video.sampleUrl,
            media: 'Video',
            priceInBDT: video.priceBdt,
            priceInGBP: video.priceGbp,
            priceInUSD: video.priceUsd,
            token: video.point,
            isEContent: video.isEContent,
            isPaid: video.isPaid,
            discountInBDT: video.discountBdt,
            discountInGBP: video.discountGbp,
            discountInUSD: video.discountUsd,
          };
        }),
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.GET_RIBBON_BOOKS_FAILED,
      });
    }
  };

export const getFeatureItems =
  () => async (dispatch: Dispatch<IMediaAction>) => {
    dispatch({
      type: ActionTypes.GET_FEATURES_IN_PROGRESS,
    });

    const path = `${envVariables.apiBaseUrl}/lookup/getallfeatures`;

    try {
      const { data } = await http.getRequest(path);

      let featuredItems: IFeatureItem[] = [];
      let featuredBookItems: IFeatureItem[] = [];
      let featuredVideoItems: IFeatureItem[] = [];
      let featuredAudioItems: IFeatureItem[] = [];

      const featuredBooks = data.filter(
        (item: any) => item.featureType === 'Book'
      );
      const featuredVideos = data.filter(
        (item: any) => item.featureType === 'Video'
      );
      const featuredAudios = data.filter(
        (item: any) => item.featureType === 'Audio'
      );

      if (featuredBooks) {
        featuredBookItems = [
          ...featuredBooks.map((item: any): IFeatureItem => {
            return {
              id: item.id,
              contentType: item.contentName,
              title: item.title,
              author: item.authorItems.map((author: any): IAuthorItem => {
                return {
                  id: author.id,
                  name: author.name,
                  role: author.authorRole,
                };
              }),
              coverArt: `${envVariables.awsBooksBaseUrl}/${item.coverImage}`,
              genre: item.genreItems.map((genre: any): IGenreItem => {
                return {
                  id: genre.id,
                  name: genre.englishName
                    ? genre.englishName
                    : genre.description,
                };
              }),
              description: item.shortDescription,
              media: 'Book',
              priceInBDT: item.priceBdt,
              priceInGBP: item.priceGbp,
              priceInUSD: item.priceUsd,
              token: item.point,
              isEContent: item.isEContent,
              isPaid: item.isPaid,
              discountInBDT: item.discountBdt,
              discountInGBP: item.discountGbp,
              discountInUSD: item.discountUsd,
            };
          }),
        ];

        featuredItems = [...featuredItems, ...featuredBookItems];
      }

      if (featuredVideos) {
        featuredVideoItems = [
          ...featuredVideos.map((item: any): IFeatureItem => {
            return {
              id: item.id,
              contentType: item.contentName,
              title: item.title,
              author: item.authorItems.map((author: any): IAuthorItem => {
                return {
                  id: author.id,
                  name: author.name,
                  role: author.authorRole,
                };
              }),
              coverArt: `${envVariables.awsVideoBaseUrl}/${item.coverImage}`,
              genre: item.genreItems.map((genre: any): IGenreItem => {
                return {
                  id: genre.id,
                  name: genre.englishName
                    ? genre.englishName
                    : genre.description,
                };
              }),
              description: item.shortDescription,
              media: 'Video',
              priceInBDT: item.priceBdt,
              priceInGBP: item.priceGbp,
              priceInUSD: item.priceUsd,
              token: item.point,
              isEContent: item.isEContent,
              isPaid: item.isPaid,
              discountInBDT: item.discountBdt,
              discountInGBP: item.discountGbp,
              discountInUSD: item.discountUsd,
            };
          }),
        ];

        featuredItems = [...featuredItems, ...featuredVideoItems];
      }

      if (featuredAudios) {
        featuredAudioItems = [
          ...featuredAudios.map((item: any): IFeatureItem => {
            return {
              id: item.id,
              contentType: item.contentName,
              title: item.title,
              author: item.authorItems.map((author: any): IAuthorItem => {
                return {
                  id: author.id,
                  name: author.name,
                  role: author.authorRole,
                };
              }),
              coverArt: `${envVariables.awsAudioBaseUrl}/${item.coverImage}`,
              genre: item.genreItems.map((genre: any): IGenreItem => {
                return {
                  id: genre.id,
                  name: genre.englishName
                    ? genre.englishName
                    : genre.description,
                };
              }),
              description: item.shortDescription,
              media: 'Audio',
              priceInBDT: item.priceBdt,
              priceInGBP: item.priceGbp,
              priceInUSD: item.priceUsd,
              token: item.point,
              isEContent: item.isEContent,
              isPaid: item.isPaid,
              discountInBDT: item.discountBdt,
              discountInGBP: item.discountGbp,
              discountInUSD: item.discountUsd,
            };
          }),
        ];

        featuredItems = [...featuredItems, ...featuredAudioItems];
      }

      dispatch({
        type: ActionTypes.GET_FEATURES_SUCCESS,
        payload: {
          features: featuredItems,
          featuredBooks: featuredBookItems,
          featuredVideo: featuredVideoItems,
          featuredAudio: featuredAudioItems,
        },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.GET_FEATURES_FAILED,
      });
    }
  };

export const getBookbyId =
  (id: number) => async (dispatch: Dispatch<IMediaAction>) => {
    dispatch({ type: ActionTypes.GET_BOOK_BY_ID_IN_PROGRESS });

    const path = `${envVariables.apiBaseUrl}/books/${id}`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_BOOK_BY_ID_SUCCESS,
        payload: {
          id: data.id,
          contentType: data.contentTypeName,
          title: data.bookTitle,
          author: data.authorItems.map((author: any): IAuthorItem => {
            return {
              id: author.id,
              name: author.name,
              role: author.authorRole,
            };
          }),
          coverArt: `${envVariables.awsBooksBaseUrl}/${data.coverImage}`,
          genre: data.genreItems.map((genre: any): IGenreItem => {
            return {
              id: genre.id,
              name: genre.englishName ? genre.englishName : genre.description,
            };
          }),
          publisher: data.publisherName,
          publisherLogo: `${envVariables.publisherLogoBaseUrl}/${data.publisherLogo}`,
          description: data.shortDescription,
          sourceUrl: data.contentUrl,
          sampleUrl: data.sampleUrl,
          media: 'Book',
          priceInBDT: data.priceBdt,
          priceInGBP: data.priceGbp,
          priceInUSD: data.priceUsd,
          token: data.point,
          isEContent: data.isEContent,
          isPaid: data.isPaid,
          discountInBDT: data.discountBdt,
          discountInGBP: data.discountGbp,
          discountInUSD: data.discountUsd,
        },
      });
    } catch (err) {
      dispatch({ type: ActionTypes.GET_BOOK_BY_ID_FAILED });
    }
  };

export const getBookBySamePublisher =
  (id: number) => async (dispatch: Dispatch<IMediaAction>) => {
    const path = `${envVariables.apiBaseUrl}/books/getbookbysamepublisher/${id}`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_BOOKS_BY_SAME_PUBLISHER,
        payload: data.map((book: any): IBook => {
          return {
            id: book.id,
            contentType: book.contentTypeName,
            title: book.bookTitle,
            author: book.authorItems.map((author: any): IAuthorItem => {
              return {
                id: author.id,
                name: author.name,
                role: author.authorRole,
              };
            }),
            coverArt: `${envVariables.awsBooksBaseUrl}/${book.coverImage}`,
            genre: book.genreItems.map((genre: any): IGenreItem => {
              return {
                id: genre.id,
                name: genre.englishName ? genre.englishName : genre.description,
              };
            }),
            publisher: book.publisherName,
            publisherLogo: `${envVariables.publisherLogoBaseUrl}/${book.publisherLogo}`,
            description: book.shortDescription,
            sourceUrl: book.contentUrl,
            sampleUrl: book.sampleUrl,
            media: 'Book',
            priceInBDT: book.priceBdt,
            priceInGBP: book.priceGbp,
            priceInUSD: book.priceUsd,
            token: book.point,
            isEContent: book.isEContent,
            isPaid: book.isPaid,
            discountInBDT: book.discountBdt,
            discountInGBP: book.discountGbp,
            discountInUSD: book.discountUsd,
          };
        }),
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.GET_BOOKS_BY_SAME_PUBLISHER_FAILED });
    }
  };

export const getBookBySameAuthor =
  (id: number) => async (dispatch: Dispatch<IMediaAction>) => {
    const path = `${envVariables.apiBaseUrl}/books/getbookbysameauthor/${id}`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_BOOKS_BY_SAME_AUTHOR,
        payload: data.map((book: any): IBook => {
          return {
            id: book.id,
            contentType: book.contentTypeName,
            title: book.bookTitle,
            author: book.authorItems.map((author: any): IAuthorItem => {
              return {
                id: author.id,
                name: author.name,
                role: author.authorRole,
              };
            }),
            coverArt: `${envVariables.awsBooksBaseUrl}/${book.coverImage}`,
            genre: book.genreItems.map((genre: any): IGenreItem => {
              return {
                id: genre.id,
                name: genre.englishName ? genre.englishName : genre.description,
              };
            }),
            publisher: book.publisherName,
            publisherLogo: `${envVariables.publisherLogoBaseUrl}/${book.publisherLogo}`,
            description: book.shortDescription,
            sourceUrl: book.contentUrl,
            sampleUrl: book.sampleUrl,
            media: 'Book',
            priceInBDT: book.priceBdt,
            priceInGBP: book.priceGbp,
            priceInUSD: book.priceUsd,
            token: book.point,
            isEContent: book.isEContent,
            isPaid: book.isPaid,
            discountInBDT: book.discountBdt,
            discountInGBP: book.discountGbp,
            discountInUSD: book.discountUsd,
          };
        }),
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.GET_BOOKS_BY_SAME_AUTHOR_FAILED });
    }
  };

export const getVideobyId =
  (id: number) => async (dispatch: Dispatch<IMediaAction>) => {
    dispatch({ type: ActionTypes.GET_VIDEO_BY_ID_IN_PROGRESS });

    const path = `${envVariables.apiBaseUrl}/videos/${id}`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_VIDEO_BY_ID_SUCCESS,
        payload: {
          id: data.id,
          contentType: data.contentTypeName,
          title: data.title,
          artist: data.authorItems.map((author: any): IAuthorItem => {
            return {
              id: author.id,
              name: author.name,
              role: author.authorRole,
            };
          }),
          coverArt: `${envVariables.awsVideoBaseUrl}/${data.coverImage}`,
          genre: data.genreItems.map((genre: any): IGenreItem => {
            return {
              id: genre.id,
              name: genre.englishName ? genre.englishName : genre.description,
            };
          }),
          publisher: data.publisherName,
          description: data.shortDescription,
          sourceUrl: data.contentUrl,
          sampleUrl: data.sampleUrl,
          media: 'Video',
          priceInBDT: data.priceBdt,
          priceInGBP: data.priceGbp,
          priceInUSD: data.priceUsd,
          token: data.point,
          isEContent: data.isEContent,
          isPaid: data.isPaid,
          discountInBDT: data.discountBdt,
          discountInGBP: data.discountGbp,
          discountInUSD: data.discountUsd,
        },
      });
    } catch (err) {
      dispatch({ type: ActionTypes.GET_VIDEO_BY_ID_FAILED });
    }
  };

export const getAudiobyId =
  (id: number) => async (dispatch: Dispatch<IMediaAction>) => {
    dispatch({ type: ActionTypes.GET_AUDIO_BY_ID_IN_PROGRESS });

    const path = `${envVariables.apiBaseUrl}/audios/${id}`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_AUDIO_BY_ID_SUCCESS,
        payload: {
          id: data.id,
          contentType: data.contentTypeName,
          title: data.audioTitle,
          artist: data.authorItems.map((artist: any): IAuthorItem => {
            return {
              id: artist.id,
              name: artist.name,
              role: artist.authorRole,
            };
          }),
          coverArt: `${envVariables.awsAudioBaseUrl}/${data.coverImage}`,
          genre: data.genreItems.map((genre: any): IGenreItem => {
            return {
              id: genre.id,
              name: genre.englishName ? genre.englishName : genre.description,
            };
          }),
          publisher: data.publisherName,
          description: data.shortDescription,
          playlist: data.albumPostCastItems.map((item: any): IPlaylistItem => {
            return {
              id: item.id,
              title: item.title,
              sourceUrl: item.contentUrl,
              artists: item.authorItems.map((artist: any): IAuthorItem => {
                return {
                  id: artist.id,
                  name: artist.name,
                  role: artist.authorRole,
                };
              }),
            };
          }),
          media: 'Audio',
          priceInBDT: data.priceBdt,
          priceInGBP: data.priceGbp,
          priceInUSD: data.priceUsd,
          token: data.point,
          isEContent: data.isEContent,
          isPaid: data.isPaid,
          discountInBDT: data.discountBdt,
          discountInGBP: data.discountGbp,
          discountInUSD: data.discountUsd,
        },
      });
    } catch (err) {
      dispatch({ type: ActionTypes.GET_AUDIO_BY_ID_FAILED });
    }
  };
