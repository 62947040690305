import React, { useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useStoreState } from '../../hooks';
import { DataLoading } from '../common';
import NoDataFound from '../common/NoDataFound';
import './index.scss';
import OrderHistoryItem from './OrderHistoryItem';

const Orders: React.FC = () => {
  const {
    order: { isOrderHistoryLoading, orders },
  } = useStoreState();

  const isOrderHistoryEmpty = useMemo(() => {
    return orders.length <= 0;
  }, [orders]);

  return (
    <Container className="orderHistory">
      <Row>
        <Col>
          {isOrderHistoryLoading && <DataLoading />}
          {isOrderHistoryEmpty && !isOrderHistoryLoading && (
            <NoDataFound dataType="order history" />
          )}
          {orders.length > 0 && !isOrderHistoryLoading && (
            <div className="orderHistory__title">
              <h3>Order History</h3>
              <hr />
            </div>
          )}
          {orders.length > 0 &&
            !isOrderHistoryLoading &&
            orders.map((order) => {
              return (
                <div key={order.id}>
                  <OrderHistoryItem order={order} />
                </div>
              );
            })}
        </Col>
      </Row>
    </Container>
  );
};

export default Orders;
