import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useCart, useLibrary, useStoreState } from '../../hooks';

const MediaSnackBar: React.FC = () => {
  const [addToCartSuccessSnacbarOpen, setAddToCartSuccessSnackbarOpen] =
    useState(false);
  const [addToLibrarySuccessSnacbarOpen, setAddToLibrarySuccessSnackbarOpen] =
    useState(false);

  const {
    cart: { isAddCartItemSuccessful },
    library: { isAddLibraryItemSuccessful },
  } = useStoreState();

  const { resetCartSuccess } = useCart();
  const { resetLibrarySuccess } = useLibrary();

  useEffect(() => {
    if (isAddCartItemSuccessful) {
      setAddToCartSuccessSnackbarOpen(true);
      resetCartSuccess();
    }
  }, [isAddCartItemSuccessful]);

  useEffect(() => {
    if (isAddLibraryItemSuccessful) {
      setAddToLibrarySuccessSnackbarOpen(true);
      resetLibrarySuccess();
    }
  }, [isAddLibraryItemSuccessful]);

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={addToCartSuccessSnacbarOpen}
        autoHideDuration={3000}
        onClose={() => setAddToCartSuccessSnackbarOpen(false)}
        message="Item added to your cart"
      />

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={addToLibrarySuccessSnacbarOpen}
        autoHideDuration={3000}
        onClose={() => setAddToLibrarySuccessSnackbarOpen(false)}
        message="Item added to your library"
      />
    </>
  );
};

export default MediaSnackBar;
