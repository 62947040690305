import React, { useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  Row,
} from 'react-bootstrap';

interface ISearchProps {
  media: string;
  placeHolderText: string;
  onSearch: (media: string, query: string) => void;
}

export const Search: React.FC<ISearchProps> = ({
  placeHolderText,
  onSearch,
  media,
}) => {
  const [query, setQuery] = useState('');

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };
  return (
    <Container>
      <Row>
        <Col>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              onSearch(media, query);
            }}
          >
            <FormControl
              onChange={handleSearchInput}
              value={query}
              type="text"
              placeholder={placeHolderText}
              className="mr-sm-2"
            />
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
