export const password = {
  validate: (password: string) => {
    //Uppercase check
    if (!password.match(new RegExp('(?=.*[A-Z])', 'g'))) {
      return false;
    }

    //Lowercase check
    if (!password.match(new RegExp('(?=.*[a-z])', 'g'))) {
      return false;
    }

    //number check
    if (!password.match(new RegExp('.*[0-9].*', 'g'))) {
      return false;
    }

    // //special character check
    // if (!password.match(new RegExp('(?=.*[@$!%*#?&])', 'g'))){
    //     return false
    // }

    //length check
    if (password.length < 8) {
      return false;
    }

    return true;
  },

  match: (password1: string, password2: string) => {
    return password1 === password2;
  },
};
