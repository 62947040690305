import { Paper } from '@material-ui/core';
import React from 'react';
import { IAudio, ILibraryItem } from '../../models';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import './audioItem.scss';
import { useRouteMatch } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStoreState } from '../../hooks';
import { Spinner } from 'react-bootstrap';

interface IAudioItemProps {
  audio: IAudio | ILibraryItem;
  onAudioSelect: (audio: IAudio | ILibraryItem) => void;
  onAddToCart: (audio: IAudio) => void;
  onAddToLibrary: (audio: IAudio) => void;
  onDeleteFromLibrary: (audio: ILibraryItem) => void;
}

export const AudioItem: React.FC<IAudioItemProps> = ({
  audio,
  onAudioSelect,
  onAddToCart,
  onAddToLibrary,
  onDeleteFromLibrary,
}) => {
  const isLibraryRoute = useRouteMatch('/library');
  const {
    cart: { isAddCartItemInProgress },
    library: { isAddLibraryItemInProgress, isDeleteLibraryItemInProgress },
  } = useStoreState();
  return (
    <Paper
      onClick={() => onAudioSelect(audio)}
      elevation={3}
      className="audioItem"
    >
      <img src={audio.coverArt} className="audioItem__image" />
      {audio.isPaid && !isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToCart(audio as IAudio);
          }}
          className="audioItem__addToCart"
        >
          {isAddCartItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddCartItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}

          {!isAddCartItemInProgress && (
            <ShoppingCartIcon className="audioItem__icon" />
          )}

          {!isAddCartItemInProgress && <div>Add to cart</div>}
        </div>
      )}
      {!audio.isPaid && !isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToLibrary(audio as IAudio);
          }}
          className="audioItem__addToCart"
        >
          {isAddLibraryItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddLibraryItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}
          {!isAddLibraryItemInProgress && (
            <LibraryBooksIcon className="audioItem__icon" />
          )}
          {!isAddLibraryItemInProgress && <div>Add to Library</div>}
        </div>
      )}

      {isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onDeleteFromLibrary(audio as ILibraryItem);
          }}
          className="audioItem__addToCart delete"
        >
          {isDeleteLibraryItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isDeleteLibraryItemInProgress && (
            <div style={{ marginLeft: 10 }}>Deleting...</div>
          )}
          {!isDeleteLibraryItemInProgress && (
            <DeleteIcon className="audioItem__icon" />
          )}
          {!isDeleteLibraryItemInProgress && <div>Delete</div>}
        </div>
      )}
    </Paper>
  );
};
