import { Dispatch } from 'redux';
import { ActionTypes } from '../types';
import { IOrderAction } from './interface';
import { envVariables, http } from '../../utility';
import { IOrderHistory, IOrderItem } from '../../models';

export const getOrderHistory =
  (token: string) => async (dispatch: Dispatch<IOrderAction>) => {
    dispatch({
      type: ActionTypes.GET_ORDER_HISTORY_ITEM_IN_PROGRESS,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const path = `${envVariables.apiBaseUrl}/orders/gettoporders`;

    try {
      const { data } = await http.getRequest(path, config);

      dispatch({
        type: ActionTypes.GET_ORDER_HISTORY_ITEM_SUCCESS,
        payload: data.map((order: any): IOrderHistory => {
          return {
            id: order.id,
            orderNumber: order.orderNumber,
            purchasedAt: order.createdAt,
            billingAddress1: order.billingAddress1,
            billingAddress2: order.billingAddress2,
            billingCity: order.billingCity,
            billingState: order.billingState,
            billingPostCode: order.billingPostalCode,
            totalAmount: order.netAmount,
            totalVat: order.vatAmount,
            totalDiscount: order.discount,
            currency: order.currencyCode,
            deliveryCharge: order.deliveryCharge,
            deliveryZoneCode: order.deliveryZoneCode,
          };
        }),
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.GET_ORDER_HISTORY_ITEM_FAILED });
    }
  };

export const getOrderById =
  (id: number, token: string) => async (dispatch: Dispatch<IOrderAction>) => {
    dispatch({
      type: ActionTypes.GET_ORDER_DETAIL_BY_ID_IN_PROGRESS,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const path = `${envVariables.apiBaseUrl}/orders/${id}`;

    try {
      const { data } = await http.getRequest(path, config);

      dispatch({
        type: ActionTypes.GET_ORDER_DETAIL_BY_ID_SUCCESS,
        payload: data.orderDetails.map((order: any): IOrderItem => {
          let itemId: number;
          let title: string;
          let contentType: string;

          if (order.bookId) {
            itemId = order.bookId;
            title = order.book;
            contentType = order.bookContentType;
          } else if (order.audioId) {
            itemId = order.audioId;
            title = order.audio;
            contentType = order.audioContentType;
          } else {
            itemId = order.viderId;
            title = order.video;
            contentType = order.videoContentType;
          }

          return {
            itemId,
            orderId: order.orderId,
            orderNumber: data.orderNumber,
            title,
            contentType,
            price: order.amount * order.qty + order.vat - order.discount,
            quantity: order.qty,
            vat: order.vat,
            currency: data.currencyCode,
            discount: order.discount,
            status: order.detailStatus,
            isEContent: contentType !== 'PaperBook',
          };
        }),
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.GET_ORDER_DETAIL_BY_ID_FAILED });
    }
  };
