import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { IUser } from '../../models';
import { date } from '../../utility';
import './profileDetailCard.scss';

interface IProfileDetailCardProps {
  user: IUser;
  registeredCountry: string;
  currentCountry: string;
  onEditProfile: () => void;
}

const ProfileDetailCard: React.FC<IProfileDetailCardProps> = ({
  user,
  registeredCountry,
  currentCountry,
  onEditProfile,
}) => {
  return (
    <Card className="profileDetailCard">
      <Card.Header>Profile Information</Card.Header>
      <Card.Body>
        {user.title && (
          <div className="profileDetailCard__info">
            <div className="profileDetailCard__infoTitle">Title: </div>
            <div className="profileDetailCard__infoDetail">{user.title}</div>
          </div>
        )}
        <div className="profileDetailCard__info">
          <div className="profileDetailCard__infoTitle">First Name: </div>
          <div className="profileDetailCard__infoDetail">{user.firstName}</div>
        </div>
        <div className="profileDetailCard__info">
          <div className="profileDetailCard__infoTitle">Last Name: </div>
          <div className="profileDetailCard__infoDetail">{user.lastName}</div>
        </div>
        <div className="profileDetailCard__info">
          <div className="profileDetailCard__infoTitle">Email: </div>
          <div className="profileDetailCard__infoDetail">{user.email}</div>
        </div>
        <div className="profileDetailCard__info">
          <div className="profileDetailCard__infoTitle">Date of birth: </div>
          <div className="profileDetailCard__infoDetail">
            {date.shortFormat(user.dob)}
          </div>
        </div>
        <div className="profileDetailCard__info">
          <div className="profileDetailCard__infoTitle">
            Registered Location:
          </div>
          <div className="profileDetailCard__infoDetail">
            {registeredCountry}
          </div>
        </div>
        <div className="profileDetailCard__info">
          <div className="profileDetailCard__infoTitle">Current Location:</div>
          <div className="profileDetailCard__infoDetail">{currentCountry}</div>
        </div>
        {user.mobile && (
          <div className="profileDetailCard__info">
            <div className="profileDetailCard__infoTitle">Mobile:</div>
            <div className="profileDetailCard__infoDetail">{user.mobile}</div>
          </div>
        )}
        {user.address1 && (
          <div className="profileDetailCard__info">
            <div className="profileDetailCard__infoTitle">Address 1:</div>
            <div className="profileDetailCard__infoDetail">{user.address1}</div>
          </div>
        )}
        {user.address2 && (
          <div className="profileDetailCard__info">
            <div className="profileDetailCard__infoTitle">Address 2:</div>
            <div className="profileDetailCard__infoDetail">{user.address2}</div>
          </div>
        )}
        {user.city && (
          <div className="profileDetailCard__info">
            <div className="profileDetailCard__infoTitle">City:</div>
            <div className="profileDetailCard__infoDetail">{user.city}</div>
          </div>
        )}
        {user.state && (
          <div className="profileDetailCard__info">
            <div className="profileDetailCard__infoTitle">State:</div>
            <div className="profileDetailCard__infoDetail">{user.state}</div>
          </div>
        )}
        {user.postCode && (
          <div className="profileDetailCard__info">
            <div className="profileDetailCard__infoTitle">Post Code:</div>
            <div className="profileDetailCard__infoDetail">{user.postCode}</div>
          </div>
        )}
        <Button onClick={onEditProfile} className="profileDetailCard__button">
          Edit Profile
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ProfileDetailCard;
