import React from 'react';
import './index.scss';
import cholishnuDarkVerticalFullLogo from '../../assets/images/cholishnuDarkVerticalFullLogo.png';
import { Alert } from 'react-bootstrap';
import ForgetPasswordForm from './ForgetPasswordForm';
import { useAuth, useRouter, useStoreState } from '../../hooks';

const ForgetPassword: React.FC = () => {
  const { navigateTo } = useRouter();
  const { forgetPasswordEmailSend } = useAuth();
  const {
    auth: {
      isForgetEmailSendingInProgress,
      isForgetEmailSendingSuccessfull,
      forgetEmailSendingError,
    },
  } = useStoreState();
  return (
    <div className="forgetPassword">
      <div className="forgetPassword__logo">
        <img src={cholishnuDarkVerticalFullLogo} />
      </div>
      <div className="forgetPassword__title">
        <h4>Forgot your Password?</h4>
        <p>Enter your registered email to recieve a temporary password</p>
      </div>
      <div className="forgetPassword__form">
        {isForgetEmailSendingSuccessfull && (
          <Alert variant="success">Email is sent successfully</Alert>
        )}
        {forgetEmailSendingError && (
          <Alert variant="danger">{forgetEmailSendingError}</Alert>
        )}
        <ForgetPasswordForm
          isLoading={isForgetEmailSendingInProgress}
          onSendEmail={forgetPasswordEmailSend}
          onNavigate={navigateTo}
        />
      </div>
    </div>
  );
};

export default ForgetPassword;
