import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './footer.scss';

const Footer: React.FC = () => {
  return (
    <Container fluid className="footer">
      <Row>
        <Col>&copy;{new Date().getFullYear()} Cholishnu Ltd.</Col>
      </Row>
    </Container>
  );
};

export default Footer;
