import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import './dataLoading.scss';

export const DataLoading: React.FC = () => {
  return (
    <div className="dataLoading">
      <div className="dataLoading__itemContainer">
        <CircularProgress size={60} className="dataLoading__spinner" />
        <Typography variant="h4">Please Wait...</Typography>
      </div>
    </div>
  );
};
