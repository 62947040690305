import { CircularProgress, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import './registerForm.scss';
import 'react-phone-number-input/style.css';
import {
  RegistrationInputEnum,
  IRegistrationInputData,
} from '../../customTypes';

interface IRegisterFormProps {
  onNavigate: (path: string) => void;
  onRegister: (formData: IRegistrationInputData, mobile: string) => void;
  onResetError: () => void;
  isLoading: boolean;
  errorField?: RegistrationInputEnum | null;
}

const RegisterForm: React.FC<IRegisterFormProps> = ({
  onNavigate,
  onRegister,
  onResetError,
  isLoading,
  errorField,
}) => {
  const [mobile, setMobile] = useState('');
  const [registerInput, setRegisterInput] = useState<IRegistrationInputData>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    dob: '',
    country: '',
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onResetError();
    setRegisterInput({
      ...registerInput,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onRegister(registerInput, mobile);
  };

  return (
    <Form onSubmit={handleSubmit} className="registerForm">
      <Form.Group controlId="formBasicEmail">
        <Form.Label className="registerForm__label">First Name</Form.Label>
        <Form.Control
          name="firstName"
          value={registerInput.firstName}
          onChange={handleInputChange}
          required
          type="text"
          placeholder="First Name"
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label className="registerForm__label">Last Name</Form.Label>
        <Form.Control
          name="lastName"
          value={registerInput.lastName}
          onChange={handleInputChange}
          required
          type="text"
          placeholder="Last Name"
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label className="registerForm__label">Email address</Form.Label>
        <Form.Control
          name="email"
          value={registerInput.email}
          onChange={handleInputChange}
          required
          type="email"
          placeholder="Enter email"
        />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label className="registerForm__label">Password</Form.Label>
        <Form.Control
          isInvalid={errorField === RegistrationInputEnum.Password}
          name="password"
          value={registerInput.password}
          onChange={handleInputChange}
          required
          type="password"
          placeholder="Password"
        />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label className="registerForm__label">
          Confirm Password
        </Form.Label>
        <Form.Control
          name="confirmPassword"
          isInvalid={errorField === RegistrationInputEnum.ConfirmPassword}
          value={registerInput.confirmPassword}
          onChange={handleInputChange}
          required
          type="password"
          placeholder="Confirm password"
        />
      </Form.Group>
      <Form.Group
        className="registerForm__dateOfBirth"
        controlId="formBasicPassword"
      >
        <Form.Label className="registerForm__label">Date of Birth</Form.Label>
        <TextField
          id="date"
          label=""
          type="date"
          name="dob"
          value={registerInput.dob}
          onChange={handleInputChange}
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label className="registerForm__label">Mobile</Form.Label>
        <PhoneInput
          className="registerForm__phoneInput"
          international
          placeholder="Enter phone number"
          defaultCountry="BD"
          value={mobile}
          onChange={setMobile}
        />
      </Form.Group>
      {isLoading && (
        <div className="registerForm__spinner">
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <Button
          className="registerForm__button"
          variant="primary"
          type="submit"
        >
          Register
        </Button>
      )}

      <div className="registerForm__linkTextContainer">
        <Form.Text
          onClick={() => {
            onNavigate('/login');
          }}
          className="registerForm__link"
        >
          Already have an account? Sign in
        </Form.Text>
      </div>
    </Form>
  );
};

export default RegisterForm;
