import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { OrderStatusEnum } from '../../customTypes';
import { IOrderItem } from '../../models';
import { date, price } from '../../utility';
import './orderDetailItem.scss';

interface IOrderDetailItemProps {
  order: IOrderItem;
}

const OrderDetailItem: React.FC<IOrderDetailItemProps> = ({ order }) => {
  const statusName = useMemo(() => {
    return order.status === OrderStatusEnum.Started
      ? 'processing'
      : order.status === OrderStatusEnum.Shipping
      ? 'dispatched'
      : 'delivered';
  }, [order]);

  const statusColor = useMemo(() => {
    return order.status === OrderStatusEnum.Started
      ? '#cc3232'
      : order.status === OrderStatusEnum.Shipping
      ? '#e7b416'
      : '#2dc937';
  }, [order]);
  return (
    <Card className="orderDetailItem">
      <Card.Body>
        <Card.Title className="orderDetailItem__title">
          {order.title}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {order.contentType}
        </Card.Subtitle>

        <Card.Text>
          <span className="orderDetailItem__infoTitle">Total Amount:</span>{' '}
          {price.format(order.currency, order.price)}{' '}
          <span className="text-muted">
            (includes vat: {price.format(order.currency, order.vat)})
          </span>
        </Card.Text>
        {order.discount > 0 && (
          <Card.Text>
            Discount:
            <span className="orderDetailItem__discount">
              {' '}
              -{price.format(order.currency, order.discount)}{' '}
            </span>
          </Card.Text>
        )}
        <Card.Text>
          <span className="orderDetailItem__infoTitle">Quatity:</span>{' '}
          {order.quantity}{' '}
        </Card.Text>
        {!order.isEContent && (
          <Card.Text>
            <span className="orderDetailItem__infoTitle">Status:</span>
            {'  '}
            <span style={{ color: statusColor }}>{statusName}</span>
          </Card.Text>
        )}
        {order.isEContent && (
          <Card.Text>
            <span className="orderDetailItem__infoTitle">Status:</span>
            {'  '}
            <span style={{ color: '#2dc937' }}>completed</span>
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};

export default OrderDetailItem;
