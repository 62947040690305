import React, { useCallback } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Paper } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import './audioDetail.scss';
import { IAudio } from '../../models';
import { price } from '../../utility';
import { useStoreState } from '../../hooks';

interface IAudioDetail {
  audio: IAudio;
  userCurrency: string;
  onAddToCart: (audio: IAudio) => void;
  onAddToLibrary: (audio: IAudio) => void;
}

const AudioDetail: React.FC<IAudioDetail> = ({
  audio,
  userCurrency,
  onAddToCart,
  onAddToLibrary,
}) => {
  const {
    cart: { isAddCartItemInProgress },
    library: { isAddLibraryItemInProgress },
  } = useStoreState();
  const hasDiscount = useCallback(
    (audio: IAudio) => {
      return (
        price.getDiscountPrice(
          userCurrency,
          audio.discountInGBP,
          audio.discountInBDT,
          audio.discountInUSD
        ) > 0 && audio.isPaid
      );
    },

    [userCurrency]
  );
  return (
    <Container className="audioDetail">
      <Row>
        <Col xl={6}>
          <Paper elevation={3} className="audioDetail__bookCard">
            <img
              alt={`${audio.title} cover`}
              src={audio.coverArt}
              className="audioDetail__image"
            />
          </Paper>
        </Col>
        <Col xl={6}>
          <Row>
            <Col className="audioDetail__basicInfo">
              <h2>{audio.title}</h2>
              <h4>{audio.contentType}</h4>
              {!hasDiscount(audio) && (
                <h6>
                  {price.formatMediaPrice(
                    userCurrency,
                    audio.priceInGBP,
                    audio.priceInBDT,
                    audio.priceInUSD
                  )}
                </h6>
              )}

              {hasDiscount(audio) && (
                <h6 className="audioDetail__price">
                  {price.formatMediaPriceAfterDiscount(
                    userCurrency,
                    audio.priceInGBP,
                    audio.priceInBDT,
                    audio.priceInUSD,
                    audio.discountInGBP,
                    audio.discountInBDT,
                    audio.discountInUSD
                  )}{' '}
                  (
                  <span className="audioDetail__discount">
                    {price.formatMediaPrice(
                      userCurrency,
                      audio.priceInGBP,
                      audio.priceInBDT,
                      audio.priceInUSD
                    )}
                  </span>
                  )
                </h6>
              )}

              {(isAddCartItemInProgress || isAddLibraryItemInProgress) && (
                <Spinner animation="border" variant="primary" />
              )}

              {(audio.isPaid || (!audio.isPaid && !audio.isEContent)) &&
                !isAddCartItemInProgress && (
                  <Button
                    onClick={() => onAddToCart(audio)}
                    className="audioDetail__button"
                  >
                    <ShoppingCartIcon className="bookItem__icon" /> Add to cart
                  </Button>
                )}

              {!audio.isPaid &&
                audio.isEContent &&
                !isAddLibraryItemInProgress && (
                  <Button
                    onClick={() => onAddToLibrary(audio)}
                    className="audioDetail__button"
                  >
                    <LibraryBooksIcon className="bookItem__icon" /> Add to
                    Library
                  </Button>
                )}
            </Col>
          </Row>
          <hr className="bookItem__divider" />
          <Row>
            <Col className="audioDetail__authorInfo">
              <h6>Artist:</h6>
              {audio.artist.map((item) => {
                return <div key={item.id}>{item.name}</div>;
              })}
            </Col>
            <Col className="audioDetail__authorInfo">
              <h6>Genres:</h6>
              {audio.genre.map((item) => {
                return <div key={item.id}>{item.name}</div>;
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="audioDetail__description">
          <h4>Description</h4>
          <hr />
          <p>{audio.description}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default AudioDetail;
