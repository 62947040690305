import React, { useEffect, useMemo, useState } from 'react';
import { AlertTypeEnum } from '../../customTypes';
import {
  useAlert,
  useLibrary,
  useLoadMedia,
  useMedia,
  useStoreState,
} from '../../hooks';
import { ILibraryItem } from '../../models';
import { AudioRibbon, BookRibbon, DataLoading, VideoRibbon } from '../common';
import CustomAlert from '../common/CustomAlert';
import NoDataFound from '../common/NoDataFound';
import './index.scss';

const Library: React.FC = () => {
  const {
    library: {
      libraryBooks,
      libraryAudios,
      libraryVideos,
      isLibraryDataLoading,
      isDeleteLibraryItemSuccessful,
    },
    auth: { userCurrency },
    alert: { type: alertType, message, isOpen: isAlertOpen },
  } = useStoreState();
  const { openAlert, closeAlert } = useAlert();
  const [selectedMedia, setSelectedMedia] = useState<ILibraryItem | null>(null);

  const { selectMedia } = useMedia();
  const { removeItemFromLibrary } = useLibrary();
  const { loadLibrary } = useLoadMedia();
  const isLibraryEmpty = useMemo(() => {
    return (
      libraryBooks.length <= 0 &&
      libraryAudios.length <= 0 &&
      libraryVideos.length <= 0
    );
  }, [libraryBooks, libraryAudios, libraryVideos]);

  useEffect(() => {
    if (isDeleteLibraryItemSuccessful) {
      loadLibrary();
    }
  }, [isDeleteLibraryItemSuccessful]);

  return (
    <div className="library">
      {isLibraryEmpty && !isLibraryDataLoading && (
        <NoDataFound dataType="library" />
      )}
      {isLibraryDataLoading && <DataLoading />}

      {libraryBooks.length > 0 && (
        <div className="library__ribbonHeader">
          <h3>My Books</h3>
          <hr className="library__divider" />
        </div>
      )}
      {libraryBooks.length > 0 && (
        <div className="library__ribbon">
          <BookRibbon
            onDeleteFromLibrary={(media) => {
              setSelectedMedia(media);
              openAlert(
                AlertTypeEnum.DeleteItemFromLibrary,
                'Are you sure you want to delete this item?'
              );
            }}
            onAddToLibrary={() => {}}
            onAddToCart={() => {}}
            onBookSelect={selectMedia}
            books={libraryBooks}
            currency={userCurrency}
          />
        </div>
      )}
      {libraryAudios.length > 0 && (
        <div className="library__ribbonHeader">
          <h3>My Audio</h3>
          <hr className="library__divider" />
        </div>
      )}
      {libraryAudios.length > 0 && (
        <div className="library__ribbon">
          <AudioRibbon
            onDeleteFromLibrary={(media) => {
              setSelectedMedia(media);
              openAlert(
                AlertTypeEnum.DeleteItemFromLibrary,
                'Are you sure you want to delete this item?'
              );
            }}
            onAddToLibrary={() => {}}
            onAddToCart={() => {}}
            onAudioSelect={selectMedia}
            audio={libraryAudios}
            currency={userCurrency}
          />
        </div>
      )}
      {libraryVideos.length > 0 && (
        <div className="library__ribbonHeader">
          <h3>My Video</h3>
          <hr className="library__divider" />
        </div>
      )}
      {libraryVideos.length > 0 && (
        <div className="library__ribbon">
          <VideoRibbon
            onDeleteFromLibrary={(media) => {
              setSelectedMedia(media);
              openAlert(
                AlertTypeEnum.DeleteItemFromLibrary,
                'Are you sure you want to delete this item?'
              );
            }}
            onAddToLibrary={() => {}}
            onAddToCart={() => {}}
            onVideoSelect={selectMedia}
            video={libraryVideos}
            currency={userCurrency}
          />
        </div>
      )}

      {alertType === AlertTypeEnum.DeleteItemFromLibrary && (
        <CustomAlert
          title="Delete Item"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          yesButtonText="Delete"
          noButtonText="Not now"
          onYesButtonPress={() => {
            if (!selectedMedia) {
              return;
            }
            removeItemFromLibrary(selectedMedia);
            closeAlert();
          }}
        />
      )}
    </div>
  );
};

export default Library;
