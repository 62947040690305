import React from 'react';
import { Pagination } from '@material-ui/lab';

interface ICustomPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageClick: (page: number) => void;
  isRounded?: boolean;
}

export const CustomPagination: React.FC<ICustomPaginationProps> = ({
  currentPage,
  totalPages,
  isRounded,
  onPageClick,
}) => {
  return (
    <>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(e, value) => {
          onPageClick(value);
        }}
        shape={isRounded ? 'round' : 'rounded'}
      />
    </>
  );
};
