import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IAudio } from '../../models';
import './playlist.scss';

interface IPlaylistProps {
  audio: IAudio;
}

const Playlist: React.FC<IPlaylistProps> = ({ audio }) => {
  return (
    <Container className="playlist">
      <Row>
        <Col className="playlist_songList">
          <h4>Playlist 🎵</h4>
          <hr />
          <ul>
            {audio.playlist.map((item) => {
              return (
                <li key={item.id} className="playlist_songItem">
                  {item.title}
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Playlist;
