import React from 'react';
import { Paper } from '@material-ui/core';
import { ISearchItem } from '../../models';
import './searchItem.scss';

interface ISearchItemProps {
  searchItem: ISearchItem;
  width: number;
  height: number;
  onItemSelect: (item: ISearchItem) => void;
}

const SearchItem: React.FC<ISearchItemProps> = ({
  searchItem,
  height,
  width,
  onItemSelect,
}) => {
  return (
    <Paper
      onClick={() => onItemSelect(searchItem)}
      style={{ width, height }}
      className="searchItem"
      elevation={3}
    >
      <img src={searchItem.coverArt} className="searchItem__image" />
    </Paper>
  );
};

export default SearchItem;
