import { Dispatch } from 'redux';
import { AlertTypeEnum } from '../../customTypes';
import { ActionTypes } from '../types';
import { IAlertAction } from './interface';

export const setAlert =
  (type: AlertTypeEnum, message: string) =>
  async (dispatch: Dispatch<IAlertAction>) => {
    dispatch({
      type: ActionTypes.SET_ALERT,
      payload: {
        alertType: type,
        message,
      },
    });
  };

export const resetAlert = () => async (dispatch: Dispatch<IAlertAction>) => {
  dispatch({
    type: ActionTypes.RESET_ALERT,
  });
};
