import React, { useCallback, useMemo, useState } from 'react';
import { useLibrary, useMedia, useStoreState } from '../../hooks';
import { media } from '../../utility';
import { Banner, BookRibbon, GenreList, Search } from '../common';
import { FeatureRibbon } from '../common/FeatureRibbon';
import MediaAlert from '../common/MediaAlert';
import MediaSnackBar from '../common/MediaSnackBar';
import './index.scss';

const Books: React.FC = () => {
  const {
    media: { ribbonBooks, featuredBooks },
    auth: { userCurrency },
  } = useStoreState();
  const [selectedGenre, setSelectedGenre] = useState('All');
  const { selectMedia, searchMedia, addMediaToCart, addMediaToLibrary } =
    useMedia();

  const { removeItemFromLibrary } = useLibrary();

  const uniqueContentType = useMemo(() => {
    if (ribbonBooks) {
      return media.getUniqueMediaContentType(ribbonBooks);
    } else {
      return [];
    }
  }, [ribbonBooks]);

  const uniqueGenres = useMemo(() => {
    if (ribbonBooks) {
      return ['All', ...media.getUniqueMediaGenres(ribbonBooks)];
    } else {
      return [];
    }
  }, [ribbonBooks]);

  const displayBooks = useMemo(() => {
    if (ribbonBooks) {
      return media.getBooksByGenre(ribbonBooks, selectedGenre);
    } else {
      return [];
    }
  }, [ribbonBooks, selectedGenre]);

  const getBooksByContentType = useCallback(
    (contentType: string) => {
      const filteredBooks = displayBooks.filter(
        (books) => books.contentType === contentType
      );

      return filteredBooks;
    },
    [ribbonBooks, selectedGenre, displayBooks]
  );

  const handleGenreSelect = (genre: string) => {
    setSelectedGenre(genre);
  };

  return (
    <div className="books">
      <div className="books__banner">
        <Banner
          title="Cholishnu Books"
          subtitle="Our aim is to offer book lovers a complete reading experience so that 
          they can read or listen to any books, any time and in the medium they want."
        />
      </div>
      <div className="books__search">
        <Search
          onSearch={searchMedia}
          media="Book"
          placeHolderText="🔎  Search Books ..."
        />
      </div>
      {featuredBooks && featuredBooks.length > 0 && (
        <div className="books__ribbonHeader">
          <h3>Featured</h3>
          <hr className="books__divider" />
        </div>
      )}

      {featuredBooks && featuredBooks.length > 0 && (
        <div style={{ marginBottom: 30 }} className="books__ribbon">
          <FeatureRibbon
            onAddToLibrary={addMediaToLibrary}
            onAddToCart={addMediaToCart}
            onFeatureSelect={selectMedia}
            features={featuredBooks}
            currency={userCurrency}
          />
        </div>
      )}

      <div className="books__genre">
        <GenreList
          selectedGenre={selectedGenre}
          genres={uniqueGenres}
          onGenreSelect={handleGenreSelect}
        />
      </div>

      {ribbonBooks &&
        ribbonBooks.length > 0 &&
        displayBooks.length > 0 &&
        uniqueContentType.map((contentType) => {
          return (
            <div key={contentType}>
              {getBooksByContentType(contentType).length > 0 && (
                <div className="books__ribbonHeader">
                  <h3>{contentType}</h3>
                  <hr className="books__divider" />
                </div>
              )}

              {ribbonBooks &&
                ribbonBooks.length > 0 &&
                displayBooks.length > 0 &&
                getBooksByContentType(contentType).length > 0 && (
                  <div className="books__ribbon">
                    <BookRibbon
                      onDeleteFromLibrary={() => {}}
                      onAddToLibrary={addMediaToLibrary}
                      onAddToCart={addMediaToCart}
                      onBookSelect={selectMedia}
                      books={getBooksByContentType(contentType)}
                      currency={userCurrency}
                    />
                  </div>
                )}
            </div>
          );
        })}
      <MediaAlert />
      <MediaSnackBar />
    </div>
  );
};

export default Books;
