import React, { useCallback } from 'react';
import { IAudio, ILibraryItem } from '../../models';
import { price, stringUtil } from '../../utility';
import { AudioItem } from './AudioItem';
import './audioRibbon.scss';

interface IAudioRibbonProps {
  audio: IAudio[] | ILibraryItem[];
  currency: string;
  onAudioSelect: (audio: IAudio | ILibraryItem) => void;
  onAddToCart: (audio: IAudio) => void;
  onAddToLibrary: (audio: IAudio) => void;
  onDeleteFromLibrary: (audio: ILibraryItem) => void;
}

export const AudioRibbon: React.FC<IAudioRibbonProps> = ({
  audio,
  currency,
  onAudioSelect,
  onAddToCart,
  onAddToLibrary,
  onDeleteFromLibrary,
}) => {
  const hasDiscount = useCallback(
    (audio: IAudio | ILibraryItem) => {
      return (
        price.getDiscountPrice(
          currency,
          audio.discountInGBP,
          audio.discountInBDT,
          audio.discountInUSD
        ) > 0 && audio.isPaid
      );
    },
    [audio, currency]
  );

  return (
    <div className="audioRibbon">
      {audio.map((audio: ILibraryItem | IAudio) => {
        return (
          <div key={audio.id} className="audioRibbon__item">
            <AudioItem
              onDeleteFromLibrary={onDeleteFromLibrary}
              onAddToLibrary={onAddToLibrary}
              onAddToCart={onAddToCart}
              onAudioSelect={onAudioSelect}
              audio={audio}
            />
            <h5>{stringUtil.limitToMaxCharacter(audio.title, 16)}</h5>
            <p>{audio.contentType}</p>
            {!hasDiscount(audio) && (
              <p>
                {price.formatMediaPrice(
                  currency,
                  audio.priceInGBP,
                  audio.priceInBDT,
                  audio.priceInUSD
                )}
              </p>
            )}

            {hasDiscount(audio) && (
              <p className="audioRibbon__price">
                {price.formatMediaPriceAfterDiscount(
                  currency,
                  audio.priceInGBP,
                  audio.priceInBDT,
                  audio.priceInUSD,
                  audio.discountInGBP,
                  audio.discountInBDT,
                  audio.discountInUSD
                )}{' '}
                (
                <span className="audioRibbon__discount">
                  {price.formatMediaPrice(
                    currency,
                    audio.priceInGBP,
                    audio.priceInBDT,
                    audio.priceInUSD
                  )}
                </span>
                )
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};
