import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './noDataFound.scss';

interface INoDataFoundProps {
  dataType: string;
}

const NoDataFound: React.FC<INoDataFoundProps> = ({ dataType }) => {
  return (
    <Container className="noDataFound">
      <Row>
        <Col className="noDataFound__container">
          <h4>Your {dataType} is empty</h4>
        </Col>
      </Row>
    </Container>
  );
};

export default NoDataFound;
