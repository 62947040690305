import React from 'react';
import { Jumbotron } from 'react-bootstrap';
import './banner.scss';

interface IBannerProps {
  title: string;
  subtitle: string;
}

export const Banner: React.FC<IBannerProps> = ({ title, subtitle }) => {
  return (
    <>
      <Jumbotron className="banner">
        <h2>{title}!</h2>
        <p>{subtitle} </p>
      </Jumbotron>
    </>
  );
};
