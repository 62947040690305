import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useCart, useCheckout, useStoreState } from '../../hooks';
import NoDataFound from '../common/NoDataFound';
import CartItems from './CartItems';
import CartSummary from './CartSummary';
import './index.scss';

const Cart: React.FC = () => {
  const {
    cart: { cartItems },
    auth: { userVat },
  } = useStoreState();
  const { removeItem, changeItemQuantity, clearCart } = useCart();
  const { checkout } = useCheckout();

  useEffect(() => {
    checkout(null, null, null);
  }, []);

  return (
    <Container className="cart">
      <Row>
        {cartItems.length <= 0 && <NoDataFound dataType="cart" />}
        {cartItems.length > 0 && (
          <>
            <Col xl={8}>
              <CartItems
                onClearCart={clearCart}
                onUpdateQuantity={changeItemQuantity}
                onRemoveItem={removeItem}
                cartItems={cartItems}
              />
            </Col>
            <Col xl={4}>
              <CartSummary
                onCheckout={checkout}
                userVat={userVat}
                cartItems={cartItems}
              />
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

export default Cart;
