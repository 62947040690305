import React, { useEffect } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import CheckIcon from '@material-ui/icons/Check';
import './index.scss';
import { useRouter } from '../../hooks';
import { usePayment } from '../../hooks/usePayment';

const PaymentSuccess: React.FC = () => {
  const { navigateTo } = useRouter();
  const { resetSSLGateway } = usePayment();

  useEffect(() => {
    resetSSLGateway();
  }, []);
  return (
    <Container className="paymentSuccess">
      <Row>
        <Col>
          <Card className="text-center">
            <Card.Body>
              <Card.Text className="text-muted">
                <CheckIcon className="paymentSuccess__icon" />
              </Card.Text>
              <Card.Title className="paymentSuccess__title">
                Payment Successful
              </Card.Title>
              <Card.Text className="text-muted">
                The E-Contents have been added to your library and the paper
                books will be processed within 24 hours
              </Card.Text>
              <Button
                onClick={() => navigateTo('/')}
                className="paymentSuccess__button"
                variant="primary"
              >
                Go to Home
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentSuccess;
