import React, { useEffect } from 'react';
import './app.scss';
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { AppRoute, privateRoute, publicRoutes } from './routes';
import Layout from './components/layout';
import { useAuth, useLoadMedia, useStoreState } from './hooks';
import Home from './components/home';

const App: React.FC = () => {
  const {
    loadFeatureItems,
    loadRibbonAudio,
    loadRibbonBooks,
    loadRibbonVideo,
    loadLibrary,
    loadOrderHistory,
    loadCartData,
  } = useLoadMedia();

  const {
    checkForToken,
    loadUserData,
    loadCountryInfo,
    loadUserCountryInfo,
    loadUserVat,
    loadDeliveryZones,
  } = useAuth();

  const {
    auth: { token, user, countries },
    cart: { isAddCartItemSuccessful, hasAddCartItemFailed },
    library: { isAddLibraryItemSuccessful },
  } = useStoreState();

  useEffect(() => {
    loadFeatureItems();
    loadRibbonAudio();
    loadRibbonBooks();
    loadRibbonVideo();
    loadCountryInfo();
    loadDeliveryZones();
    checkForToken();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (token) {
      loadUserData(token);
    }
    //eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (user) {
      loadUserCountryInfo(user.currentCountry, user.registeredCountry);
      loadLibrary();
      loadOrderHistory();
      loadCartData();
    }
    //eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (user && countries) {
      const foundUserCountry = countries.find(
        (country) => country.code === user.registeredCountry
      );

      if (foundUserCountry) {
        loadUserVat(foundUserCountry.vat);
      }
    }
    //eslint-disable-next-line
  }, [user, countries]);

  useEffect(() => {
    if (isAddCartItemSuccessful) {
      loadCartData();
    }
    //eslint-disable-next-line
  }, [isAddCartItemSuccessful]);

  useEffect(() => {
    if (isAddLibraryItemSuccessful) {
      loadLibrary();
    }
    //eslint-disable-next-line
  }, [isAddLibraryItemSuccessful]);

  useEffect(() => {
    if (hasAddCartItemFailed) {
      loadLibrary();
    }
  }, [hasAddCartItemFailed]);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Layout>
              <Home />
            </Layout>
          )}
        />
        {publicRoutes.map((route, idx) => (
          <AppRoute
            path={route.path}
            component={route.component}
            layout={Layout}
            key={idx}
            isProtectedRoute={false}
          />
        ))}
        {privateRoute.map((route, idx) => (
          <AppRoute
            path={route.path}
            component={route.component}
            layout={Layout}
            key={idx}
            isProtectedRoute={true}
          />
        ))}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default App;
