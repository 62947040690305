import React, { useCallback } from 'react';
import { Button, Col, Container, Row, Spinner } from 'react-bootstrap';
import { Paper } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import './bookDetail.scss';
import { IBook } from '../../models';
import { price } from '../../utility';
import { useStoreState } from '../../hooks';

interface IBookDetail {
  book: IBook;
  userCurrency: string;
  onAddToCart: (book: IBook) => void;
  onAddToLibrary: (book: IBook) => void;
}

const BookDetail: React.FC<IBookDetail> = ({
  book,
  userCurrency,
  onAddToCart,
  onAddToLibrary,
}) => {
  const {
    cart: { isAddCartItemInProgress },
    library: { isAddLibraryItemInProgress },
  } = useStoreState();
  const hasDiscount = useCallback(
    (book: IBook) => {
      return (
        price.getDiscountPrice(
          userCurrency,
          book.discountInGBP,
          book.discountInBDT,
          book.discountInUSD
        ) > 0 && book.isPaid
      );
    },
    [book, userCurrency]
  );
  return (
    <Container className="bookDetail">
      <Row>
        <Col xl={4}>
          <Paper elevation={3} className="bookDetail__bookCard">
            <img src={book.coverArt} className="bookDetail__image" />
          </Paper>
        </Col>
        <Col xl={8}>
          <Row>
            <Col className="bookDetail__basicInfo">
              <h2>{book.title}</h2>
              <h4>{book.contentType}</h4>
              {!hasDiscount(book) && (
                <h6>
                  {price.formatMediaPrice(
                    userCurrency,
                    book.priceInGBP,
                    book.priceInBDT,
                    book.priceInUSD
                  )}
                </h6>
              )}

              {hasDiscount(book) && (
                <h6 className="bookDetail__price">
                  {price.formatMediaPriceAfterDiscount(
                    userCurrency,
                    book.priceInGBP,
                    book.priceInBDT,
                    book.priceInUSD,
                    book.discountInGBP,
                    book.discountInBDT,
                    book.discountInUSD
                  )}{' '}
                  (
                  <span className="bookDetail__discount">
                    {price.formatMediaPrice(
                      userCurrency,
                      book.priceInGBP,
                      book.priceInBDT,
                      book.priceInUSD
                    )}
                  </span>
                  )
                </h6>
              )}

              {(isAddCartItemInProgress || isAddLibraryItemInProgress) && (
                <Spinner animation="border" variant="primary" />
              )}

              {(book.isPaid || (!book.isPaid && !book.isEContent)) &&
                !isAddCartItemInProgress && (
                  <Button
                    onClick={() => onAddToCart(book)}
                    className="bookDetail__button"
                  >
                    <ShoppingCartIcon className="bookDetail__icon" /> Add to
                    cart
                  </Button>
                )}

              {!book.isPaid && book.isEContent && !isAddLibraryItemInProgress && (
                <Button
                  onClick={() => onAddToLibrary(book)}
                  className="bookDetail__button"
                >
                  <LibraryBooksIcon className="bookDetail__icon" /> Add to
                  Library
                </Button>
              )}
            </Col>
          </Row>
          <hr className="bookDetail__divider" />
          <Row>
            <Col className="bookDetail__authorInfo">
              <h6>Authors:</h6>
              {book.author.map((item) => {
                return <div key={item.id}>{item.name}</div>;
              })}
            </Col>
            <Col className="bookDetail__authorInfo">
              <h6>Genres:</h6>
              {book.genre.map((item) => {
                return <div key={item.id}>{item.name}</div>;
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="bookDetail__description">
          <h4>Description</h4>
          <hr />
          <p>{book.description}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default BookDetail;
