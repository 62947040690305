export const stringUtil = {
  limitToMaxCharacter: (str: string, maxLength: number) => {
    if (!str) {
      return 'untitled';
    }
    return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
  },

  getInitialsFromFullName: (firstName: string, lastName: string) => {
    return `${firstName.toUpperCase()[0]}${lastName.toUpperCase()[0]}`;
  },
};
