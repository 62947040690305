import { parsePhoneNumber } from 'react-phone-number-input';
import countries from 'i18n-iso-countries';

export const mobile = {
  validate: (mobileNumber: string) => {
    const phoneNumber = parsePhoneNumber(mobileNumber);

    if (phoneNumber && phoneNumber.country) {
      return true;
    } else {
      return false;
    }
  },
  getCountryCode: (mobileNumber: string) => {
    const phoneNumber = parsePhoneNumber(mobileNumber);

    if (phoneNumber && phoneNumber.country) {
      return countries.alpha2ToAlpha3(phoneNumber.country);
    } else {
      return 'BGD';
    }
  },

  covertAlpha3ToAlpha2: (alpha3: string) => {
    return countries.alpha3ToAlpha2(alpha3);
  },
};
