import React from 'react';
import { AlertTypeEnum } from '../../customTypes';
import { useAlert, useRouter, useStoreState } from '../../hooks';
import CustomAlert from './CustomAlert';

const MediaAlert: React.FC = () => {
  const { closeAlert } = useAlert();
  const { navigateTo } = useRouter();
  const {
    alert: { type: alertType, isOpen: isAlertOpen },
  } = useStoreState();
  return (
    <>
      {alertType === AlertTypeEnum.SignInRequired && (
        <CustomAlert
          title="Sign in Required"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          yesButtonText="Sign in"
          noButtonText="Not now"
          onYesButtonPress={() => {
            navigateTo('/login');
            closeAlert();
          }}
        />
      )}

      {alertType === AlertTypeEnum.ItemAlreadyInCart && (
        <CustomAlert
          title="Item in Cart"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          yesButtonText="Go to cart"
          noButtonText="Cancel"
          onYesButtonPress={() => {
            navigateTo('/cart');
            closeAlert();
          }}
        />
      )}

      {alertType === AlertTypeEnum.ItemAlreadInLibrary && (
        <CustomAlert
          title="Item in Library"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          yesButtonText="Go to library"
          noButtonText="Cancel"
          onYesButtonPress={() => {
            navigateTo('/library');
            closeAlert();
          }}
        />
      )}
      {alertType === AlertTypeEnum.BackendAlert && (
        <CustomAlert
          title="Failed Request"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          noButtonText="Ok"
          onYesButtonPress={() => {}}
        />
      )}
    </>
  );
};

export default MediaAlert;
