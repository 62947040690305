import React, { useCallback } from 'react';
import { constants } from '../../utility';
import './genreList.scss';

interface IGenreListProps {
  genres: string[];
  selectedGenre: string;
  onGenreSelect: (genre: string) => void;
}

export const GenreList: React.FC<IGenreListProps> = ({
  genres,
  selectedGenre,
  onGenreSelect,
}) => {
  const isGenreActive = useCallback(
    (genre: string) => {
      return selectedGenre === genre;
    },
    [selectedGenre, genres]
  );
  return (
    <div className="genreList">
      {genres.map((genre, index) => {
        return (
          <div
            style={{
              color: isGenreActive(genre) ? constants.accentColor : '',
              opacity: isGenreActive(genre) ? 1 : 0.3,
              fontWeight: isGenreActive(genre) ? 'bold' : 'normal',
            }}
            key={index}
            className="genreList__genre"
            onClick={() => onGenreSelect(genre)}
          >
            {genre}
          </div>
        );
      })}
    </div>
  );
};
