import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './forgetPasswordForm.scss';

interface IForgetPasswordFormProps {
  onNavigate: (path: string) => void;
  onSendEmail: (email: string) => void;
  isLoading: boolean;
}

const ForgetPasswordForm: React.FC<IForgetPasswordFormProps> = ({
  onNavigate,
  onSendEmail,
  isLoading,
}) => {
  const [email, setEmail] = useState('');

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSendEmail(email);
  };

  return (
    <Form onSubmit={handleSubmit} className="forgetPasswordForm">
      <Form.Group controlId="formBasicEmail">
        <Form.Label className="forgetPasswordForm__label">
          Email address
        </Form.Label>
        <Form.Control
          value={email}
          onChange={handleInputChange}
          required
          type="email"
          placeholder="Enter email"
        />
      </Form.Group>
      {isLoading && (
        <div className="forgetPasswordForm__spinner">
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <Button
          className="forgetPasswordForm__button"
          variant="primary"
          type="submit"
        >
          Send
        </Button>
      )}

      <div className="forgetPasswordForm__linkTextContainer">
        <Form.Text
          onClick={() => {
            onNavigate('/login');
          }}
          className="forgetPasswordForm__link"
        >
          Remember your password? Sign in
        </Form.Text>
      </div>
    </Form>
  );
};

export default ForgetPasswordForm;
