import { Paper } from '@material-ui/core';
import React from 'react';
import { IBook, ILibraryItem } from '../../models';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import './bookItem.scss';
import { useRouteMatch } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { Spinner } from 'react-bootstrap';
import { useStoreState } from '../../hooks';

interface IBookItemProps {
  book: IBook | ILibraryItem;
  onBookSelect: (book: IBook | ILibraryItem) => void;
  onAddToCart: (book: IBook) => void;
  onAddToLibrary: (book: IBook) => void;
  onDeleteFromLibrary: (book: ILibraryItem) => void;
}

export const BookItem: React.FC<IBookItemProps> = ({
  book,
  onBookSelect,
  onAddToCart,
  onAddToLibrary,
  onDeleteFromLibrary,
}) => {
  const isLibraryRoute = useRouteMatch('/library');
  const {
    cart: { isAddCartItemInProgress },
    library: { isAddLibraryItemInProgress, isDeleteLibraryItemInProgress },
  } = useStoreState();
  return (
    <Paper
      onClick={() => onBookSelect(book)}
      elevation={3}
      className="bookItem"
    >
      <img src={book.coverArt} className="bookItem__image" />
      {(book.isPaid || (!book.isPaid && !book.isEContent)) && !isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToCart(book as IBook);
          }}
          className="bookItem__addToCart"
        >
          {isAddCartItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddCartItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}

          {!isAddCartItemInProgress && (
            <ShoppingCartIcon className="bookItem__icon" />
          )}

          {!isAddCartItemInProgress && <div>Add to cart</div>}
        </div>
      )}
      {!book.isPaid && book.isEContent && !isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onAddToLibrary(book as IBook);
          }}
          className="bookItem__addToCart"
        >
          {isAddLibraryItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isAddLibraryItemInProgress && (
            <div style={{ marginLeft: 10 }}>Adding...</div>
          )}
          {!isAddLibraryItemInProgress && (
            <LibraryBooksIcon className="bookItem__icon" />
          )}
          {!isAddLibraryItemInProgress && <div>Add to Library</div>}
        </div>
      )}

      {isLibraryRoute && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onDeleteFromLibrary(book as ILibraryItem);
          }}
          className="bookItem__addToCart delete"
        >
          {isDeleteLibraryItemInProgress && (
            <Spinner animation="border" variant="inherit" />
          )}
          {isDeleteLibraryItemInProgress && (
            <div style={{ marginLeft: 10 }}>Deleting...</div>
          )}
          {!isDeleteLibraryItemInProgress && (
            <DeleteIcon className="bookItem__icon" />
          )}
          {!isDeleteLibraryItemInProgress && <div>Delete</div>}
        </div>
      )}
    </Paper>
  );
};
