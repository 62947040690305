import React, { useCallback, useMemo } from 'react';
import { Card, Form } from 'react-bootstrap';
import './shippingDetails.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { ICheckoutAddressInput, IShippingUserInput } from '../../customTypes';
import { useStoreState } from '../../hooks';
import { mobile as phoneUtils } from '../../utility';
import { IDeliveryZone } from '../../models';

interface IShippingDetails {
  mobile: string;
  userInput: IShippingUserInput;
  addressInput: ICheckoutAddressInput;
  onUserInputChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onAddressInputChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onMobileChange: (value: string) => void;
}

const ShippingDetails: React.FC<IShippingDetails> = ({
  mobile,
  userInput,
  addressInput,
  onUserInputChange,
  onAddressInputChange,
  onMobileChange,
}) => {
  const {
    auth: { user, countries, deliveryZones, userCurrency },
  } = useStoreState();

  const defaultMobileCode = useMemo(() => {
    if (user && user.registeredCountry) {
      return user.registeredCountry;
    } else {
      return 'BD';
    }
  }, [user]);

  const deliveryCostWithSymbol = useCallback(
    (deliveryZone: IDeliveryZone) => {
      if (userCurrency === 'BDT') {
        return `৳${deliveryZone.chargeInBDT.toFixed(2)}`;
      } else if (userCurrency === 'GBP') {
        return `£${deliveryZone.chargeInGBP.toFixed(2)}`;
      } else {
        return `$${deliveryZone.chargeInUSD.toFixed(2)}`;
      }
    },
    [userCurrency]
  );

  return (
    <Card className="shippingDetails">
      <Card.Body>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label className="shippingDetails__label">Title*</Form.Label>
          <Form.Control
            value={userInput.title}
            name="title"
            onChange={onUserInputChange}
            required
            as="select"
          >
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Dr">Dr</option>
            <option value="Ms">Ms</option>
            <option value="Jr">Jr</option>
            <option value="Hon">Hon</option>
            <option value="Prof">Prof</option>
            <option value="Sr">Sr</option>
            <option value="St">St</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="shippingDetails__label">
            First Name*
          </Form.Label>
          <Form.Control
            name="firstName"
            value={userInput.firstName}
            onChange={onUserInputChange}
            required
            type="text"
            placeholder="First Name"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="shippingDetails__label">Last Name*</Form.Label>
          <Form.Control
            name="lastName"
            value={userInput.lastName}
            onChange={onUserInputChange}
            required
            type="text"
            placeholder="Last Name"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="shippingDetails__label">Address 1*</Form.Label>
          <Form.Control
            name="shippingAddress1"
            value={addressInput.shippingAddress1}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="Address 1"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="shippingDetails__label">Address 2</Form.Label>
          <Form.Control
            value={addressInput.shippingAddress2}
            onChange={onAddressInputChange}
            name="shippingAddress2"
            type="text"
            placeholder="Address 2"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="shippingDetails__label">City*</Form.Label>
          <Form.Control
            name="shippingCity"
            value={addressInput.shippingCity}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="City"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="shippingDetails__label">State*</Form.Label>
          <Form.Control
            name="shippingState"
            value={addressInput.shippingState}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="State"
          />
        </Form.Group>
        <Form.Group controlId="formBasicEmail">
          <Form.Label className="shippingDetails__label">PostCode*</Form.Label>
          <Form.Control
            name="shippingPostCode"
            value={addressInput.shippingPostCode}
            onChange={onAddressInputChange}
            required
            type="text"
            placeholder="PostCode"
          />
        </Form.Group>

        {countries && (
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label className="shippingDetails__label">Country*</Form.Label>
            <Form.Control
              value={addressInput.shippingCountryCode}
              name="shippingCountryCode"
              onChange={onAddressInputChange}
              required
              as="select"
            >
              {countries.map((country) => {
                return (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        )}
        {deliveryZones && (
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label className="shippingDetails__label">
              Delivery Zone*
            </Form.Label>
            <Form.Control
              value={addressInput.deliveryZoneCode}
              name="deliveryZoneCode"
              onChange={onAddressInputChange}
              required
              as="select"
            >
              {deliveryZones.map((deliveryZone) => {
                return (
                  <option key={deliveryZone.id} value={deliveryZone.zoneCode}>
                    {deliveryZone.zoneName}{' '}
                    {`(${deliveryCostWithSymbol(deliveryZone)})`}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        )}
        <Form.Group controlId="formBasicPassword">
          <Form.Label className="shippingDetails__label">Mobile*</Form.Label>
          <PhoneInput
            className="shippingDetails__phoneInput"
            international
            placeholder="Enter phone number"
            defaultCountry={phoneUtils.covertAlpha3ToAlpha2(defaultMobileCode)}
            value={mobile}
            onChange={onMobileChange}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ShippingDetails;
