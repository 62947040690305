import React, { useEffect } from 'react';
import { AlertTypeEnum } from '../../customTypes';
import {
  useAlert,
  useLoadMedia,
  useMedia,
  useRouter,
  useStoreState,
} from '../../hooks';
import { DataLoading } from '../common';
import CustomAlert from '../common/CustomAlert';
import MediaAlert from '../common/MediaAlert';
import MediaSnackBar from '../common/MediaSnackBar';
import AudioDetail from './AudioDetail';
import './index.scss';
import Playlist from './Playlist';

const AudioById: React.FC = () => {
  const { getParams, navigateTo } = useRouter();
  const { loadAudioById } = useLoadMedia();
  const { closeAlert } = useAlert();

  const {
    auth: { userCurrency },
    media: { currentAudio, isCurrentAudioLoading, hasCurrentAudioErrorOccured },
    alert: { type: alertType, isOpen: isAlertOpen },
  } = useStoreState();
  const { addMediaToCart, addMediaToLibrary } = useMedia();

  useEffect(() => {
    const id = getParams().id;
    loadAudioById(+id);
    //eslint-disable-next-line
  }, [getParams().id]);

  useEffect(() => {
    if (hasCurrentAudioErrorOccured) {
      navigateTo('/');
    }
    //eslint-disable-next-line
  }, [hasCurrentAudioErrorOccured]);

  if (isCurrentAudioLoading || !currentAudio) {
    return <DataLoading />;
  } else {
    return (
      <div className="audioById">
        <AudioDetail
          onAddToLibrary={addMediaToLibrary}
          onAddToCart={addMediaToCart}
          audio={currentAudio}
          userCurrency={userCurrency}
        />
        <Playlist audio={currentAudio} />
        <MediaAlert />
        <MediaSnackBar />
      </div>
    );
  }
};

export default AudioById;
