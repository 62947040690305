import React from 'react';
import { IFeatureItem, IVideo } from '../../models';
import { price, stringUtil } from '../../utility';
import { AudioItem } from './AudioItem';
import './featureRibbon.scss';
import { FeatureItem } from './FeatureItem';

interface IFeatureRibbon {
  features: IFeatureItem[];
  currency: string;
  onFeatureSelect: (feature: IFeatureItem) => void;
  onAddToCart: (feature: IFeatureItem) => void;
  onAddToLibrary: (feature: IFeatureItem) => void;
}

export const FeatureRibbon: React.FC<IFeatureRibbon> = ({
  features,
  currency,
  onFeatureSelect,
  onAddToCart,
  onAddToLibrary,
}) => {
  return (
    <div className="featureRibbon">
      {features.map((feature, index) => {
        return (
          <div key={index} className="featureRibbon__item">
            <FeatureItem
              onAddToLibrary={onAddToLibrary}
              onAddToCart={onAddToCart}
              currency={currency}
              onFeatureSelect={onFeatureSelect}
              featureItem={feature}
            />
          </div>
        );
      })}
    </div>
  );
};
