import { AlertTypeEnum } from '../customTypes';
import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';

export const useAlert = () => {
  const { setAlert, resetAlert } = useStoreActions();
  const {
    auth: { token, userVat },
  } = useStoreState();

  const openAlert = (type: AlertTypeEnum, message: string) => {
    setAlert(type, message);
  };

  const closeAlert = () => {
    resetAlert();
  };

  return { openAlert, closeAlert };
};
