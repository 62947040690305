export const price = {
  format: (currency: string, amount: number) => {
    if (currency === 'BDT') {
      return `৳${amount.toFixed(2)}`;
    } else if (currency === 'GBP') {
      return `£${amount.toFixed(2)}`;
    } else {
      return `$${amount.toFixed(2)}`;
    }
  },

  formatMediaPrice: (
    currency: string,
    amountInGbp: number,
    amountInBdt: number,
    amountInUsd: number
  ) => {
    if (currency === 'BDT') {
      return `৳${amountInBdt.toFixed(2)}`;
    } else if (currency === 'GBP') {
      return `£${amountInGbp.toFixed(2)}`;
    } else {
      return `$${amountInUsd.toFixed(2)}`;
    }
  },

  getMediaPrice: (
    currency: string,
    amountInGbp: number,
    amountInBdt: number,
    amountInUsd: number
  ) => {
    if (currency === 'BDT') {
      return amountInBdt;
    } else if (currency === 'GBP') {
      return amountInGbp;
    } else {
      return amountInUsd;
    }
  },

  formatMediaPriceAfterDiscount: (
    currency: string,
    amountInGbp: number,
    amountInBdt: number,
    amountInUsd: number,
    discountInGbp: number,
    discountInBdt: number,
    discountInUsd: number
  ) => {
    if (currency === 'BDT') {
      return `৳${(amountInBdt - discountInBdt).toFixed(2)}`;
    } else if (currency === 'GBP') {
      return `£${(amountInGbp - discountInGbp).toFixed(2)}`;
    } else {
      return `$${(amountInUsd - discountInUsd).toFixed(2)}`;
    }
  },

  getDiscountPrice: (
    currency: string,
    discountInGbp: number,
    discountInBdt: number,
    discountInUsd: number
  ) => {
    if (currency === 'BDT') {
      return discountInBdt;
    } else if (currency === 'GBP') {
      return discountInGbp;
    } else {
      return discountInUsd;
    }
  },

  getMediaPriceAfterDiscount: (
    currency: string,
    amountInGbp: number,
    amountInBdt: number,
    amountInUsd: number,
    discountInGbp: number,
    discountInBdt: number,
    discountInUsd: number
  ) => {
    if (currency === 'BDT') {
      return amountInBdt - discountInBdt;
    } else if (currency === 'GBP') {
      return amountInGbp - discountInGbp;
    } else {
      return amountInUsd - discountInUsd;
    }
  },
};
