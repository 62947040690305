import { ILibraryItem } from '../../models';
import { ILibraryAction } from '../actions/interface';
import { ActionTypes } from '../types';

interface ILibraryReducer {
  libraryBooks: ILibraryItem[];
  libraryVideos: ILibraryItem[];
  libraryAudios: ILibraryItem[];
  isLibraryDataLoading: boolean;
  isAddLibraryItemInProgress: boolean;
  hasAddLibraryItemFailed: boolean;
  isAddLibraryItemSuccessful: boolean;
  isDeleteLibraryItemInProgress: boolean;
  hasDeleteLibraryItemFailed: boolean;
  isDeleteLibraryItemSuccessful: boolean;
}

const initialState = {
  libraryBooks: [],
  libraryVideos: [],
  libraryAudios: [],
  isLibraryDataLoading: false,
  isAddLibraryItemInProgress: false,
  hasAddLibraryItemFailed: false,
  isAddLibraryItemSuccessful: false,
  isDeleteLibraryItemInProgress: false,
  hasDeleteLibraryItemFailed: false,
  isDeleteLibraryItemSuccessful: false,
};

export default (
  state: ILibraryReducer = initialState,
  action: ILibraryAction
): ILibraryReducer => {
  switch (action.type) {
    case ActionTypes.GET_LIBRARY_ITEM_IN_PROGRESS:
      return {
        ...state,
        libraryBooks: [],
        libraryVideos: [],
        libraryAudios: [],
        isLibraryDataLoading: true,
      };
    case ActionTypes.GET_LIBRARY_ITEM_SUCCESS:
      return {
        ...state,
        libraryBooks: action.payload.libraryBooks,
        libraryVideos: action.payload.libraryVideos,
        libraryAudios: action.payload.libraryAudios,
        isLibraryDataLoading: false,
      };
    case ActionTypes.GET_LIBRARY_ITEM_FAILED:
      return {
        ...state,
        libraryBooks: [],
        libraryVideos: [],
        libraryAudios: [],
        isLibraryDataLoading: false,
      };
    case ActionTypes.ADD_ITEM_TO_LIBRARY_IN_PROGRESS:
      return {
        ...state,
        isAddLibraryItemInProgress: true,
        isAddLibraryItemSuccessful: false,
        hasAddLibraryItemFailed: false,
      };
    case ActionTypes.ADD_ITEM_TO_LIBRARY_SUCCESS:
      return {
        ...state,
        isAddLibraryItemInProgress: false,
        isAddLibraryItemSuccessful: true,
        hasAddLibraryItemFailed: false,
      };
    case ActionTypes.ADD_ITEM_TO_LIBRARY_FAILED:
      return {
        ...state,
        isAddLibraryItemInProgress: false,
        isAddLibraryItemSuccessful: false,
        hasAddLibraryItemFailed: true,
      };
    case ActionTypes.DELETE_LIBRARY_ITEM_IN_PROGRESS:
      return {
        ...state,
        isDeleteLibraryItemInProgress: true,
        isDeleteLibraryItemSuccessful: false,
        hasDeleteLibraryItemFailed: false,
      };
    case ActionTypes.DELETE_LIBRARY_ITEM_SUCCESS:
      return {
        ...state,
        isDeleteLibraryItemInProgress: false,
        isDeleteLibraryItemSuccessful: true,
        hasDeleteLibraryItemFailed: false,
        libraryBooks:
          action.payload.media === 'Book'
            ? state.libraryBooks.filter(
                (book) => book.id !== action.payload.itemId
              )
            : state.libraryBooks,
        libraryAudios:
          action.payload.media === 'Audio'
            ? state.libraryAudios.filter(
                (audio) => audio.id !== action.payload.itemId
              )
            : state.libraryAudios,
        libraryVideos:
          action.payload.media === 'Video'
            ? state.libraryVideos.filter(
                (video) => video.id !== action.payload.itemId
              )
            : state.libraryVideos,
      };
    case ActionTypes.DELETE_LIBRARY_ITEM_FAILED:
      return {
        ...state,
        isDeleteLibraryItemInProgress: false,
        isDeleteLibraryItemSuccessful: false,
        hasDeleteLibraryItemFailed: true,
      };
    case ActionTypes.RESET_ADD_TO_LIBRARY_SUCCESS:
      return {
        ...state,
        isAddLibraryItemInProgress: false,
        isAddLibraryItemSuccessful: false,
        hasAddLibraryItemFailed: false,
      };
    default:
      return state;
  }
};
