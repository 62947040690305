import React from 'react';
import './index.scss';
import cholishnuDarkVerticalFullLogo from '../../assets/images/cholishnuDarkVerticalFullLogo.png';
import { Alert } from 'react-bootstrap';
import RegisterForm from './RegisterForm';
import { useAuth, useRouter, useStoreState } from '../../hooks';

const Register: React.FC = () => {
  const { navigateTo } = useRouter();
  const { createAccount, resetRegistrationError } = useAuth();
  const {
    auth: {
      isRegistrationInProgress,
      registrationError,
      isRegistrationSuccessfull,
    },
  } = useStoreState();
  return (
    <div className="register">
      <div className="register__logo">
        <img src={cholishnuDarkVerticalFullLogo} />
      </div>
      <div className="register__title">
        <h3>Create a new account</h3>
      </div>
      <div className="register__form">
        {registrationError && (
          <Alert variant="danger">{registrationError.errorMessage}</Alert>
        )}
        {isRegistrationSuccessfull && (
          <Alert variant="success">
            Registration is successfull. Check your email to verify your
            account!
          </Alert>
        )}
        <RegisterForm
          errorField={registrationError?.errorField}
          isLoading={isRegistrationInProgress}
          onRegister={createAccount}
          onResetError={resetRegistrationError}
          onNavigate={navigateTo}
        />
      </div>
    </div>
  );
};

export default Register;
