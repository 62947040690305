import { IOrderHistory, IOrderItem } from '../../models';
import { IOrderAction } from '../actions/interface';
import { ActionTypes } from '../types';

interface IOrderReducer {
  orders: IOrderHistory[];
  currentOrder: IOrderItem[] | null;
  isOrderHistoryLoading: boolean;
  isOrderDetailLoading: boolean;
  hasOrderDetailErrorOccured: boolean;
}

const initialState = {
  orders: [],
  currentOrder: null,
  isOrderHistoryLoading: false,
  isOrderDetailLoading: false,
  hasOrderDetailErrorOccured: false,
};

export default (
  state: IOrderReducer = initialState,
  action: IOrderAction
): IOrderReducer => {
  switch (action.type) {
    case ActionTypes.GET_ORDER_HISTORY_ITEM_IN_PROGRESS:
      return {
        ...state,
        orders: [],
        isOrderHistoryLoading: true,
      };
    case ActionTypes.GET_ORDER_HISTORY_ITEM_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        isOrderHistoryLoading: false,
      };
    case ActionTypes.GET_ORDER_HISTORY_ITEM_FAILED:
      return {
        ...state,
        orders: [],
        isOrderHistoryLoading: false,
      };
    case ActionTypes.GET_ORDER_DETAIL_BY_ID_IN_PROGRESS:
      return {
        ...state,
        currentOrder: [],
        isOrderDetailLoading: true,
        hasOrderDetailErrorOccured: false,
      };
    case ActionTypes.GET_ORDER_DETAIL_BY_ID_SUCCESS:
      return {
        ...state,
        currentOrder: action.payload,
        isOrderDetailLoading: false,
        hasOrderDetailErrorOccured: false,
      };
    case ActionTypes.GET_ORDER_DETAIL_BY_ID_FAILED:
      return {
        ...state,
        currentOrder: null,
        isOrderDetailLoading: false,
        hasOrderDetailErrorOccured: true,
      };

    default:
      return state;
  }
};
