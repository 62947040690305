import { Dispatch } from 'redux';
import {
  AlertTypeEnum,
  ICheckoutAddressInput,
  IShippingUserInput,
} from '../../customTypes';
import { IDeliveryZone } from '../../models';
import { envVariables, error, http } from '../../utility';
import { ActionTypes } from '../types';
import { IAlertAction, ICheckoutAction } from './interface';

export const setCheckoutPrice =
  (
    totalPrice: number | null,
    totalDiscount: number | null,
    totalVat: number | null
  ) =>
  async (dispatch: Dispatch<ICheckoutAction>) => {
    dispatch({
      type: ActionTypes.SET_CHECKOUT_PRICE,
      payload: {
        totalPrice,
        totalDiscount,
        totalVat,
      },
    });
  };

export const setSSLGateway =
  (gateWay: string | null) => async (dispatch: Dispatch<ICheckoutAction>) => {
    dispatch({
      type: ActionTypes.SET_SSL_GATEWAY,
      payload: gateWay,
    });
  };

export const purchaseWithSSL =
  (
    totalAmount: number,
    currency: string,
    countryCode: string,
    userInfo: IShippingUserInput,
    userAddressInfo: ICheckoutAddressInput,
    mobile: string,
    token: string,
    deliveryZoneInfo: IDeliveryZone
  ) =>
  async (dispatch: Dispatch<ICheckoutAction | IAlertAction>) => {
    dispatch({
      type: ActionTypes.PURCHASE_SSL_IN_PROGRESS,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const deliveryCharge =
      currency === 'BDT'
        ? deliveryZoneInfo.chargeInBDT
        : currency === 'GBP'
        ? deliveryZoneInfo.chargeInGBP
        : deliveryZoneInfo.chargeInUSD;

    let reqData: any = {
      totalAmount: `${totalAmount + deliveryCharge}`,
      currency,
      cusName: `${userInfo.firstName} ${userInfo.lastName}`,
      cusEmail: userInfo.email,
      cusAdd1: userAddressInfo.billingAddress1,
      cusAdd2: userAddressInfo.billingAddress2,
      cusCity: userAddressInfo.billingCity,
      cusPostcode: userAddressInfo.billingPostCode,
      cusCountry: countryCode,
      cusPhone: mobile,
      shipName: `${userInfo.firstName} ${userInfo.lastName}`,
      shipAdd1: userAddressInfo.shippingAddress1,
      shipAdd2: userAddressInfo.shippingAddress2,
      shipCity: userAddressInfo.shippingCity,
      shipState: userAddressInfo.shippingState,
      shipPostCode: userAddressInfo.shippingPostCode,
      shipCountry: userAddressInfo.shippingCountryCode,
      deliveryZoneCode: userAddressInfo.deliveryZoneCode,
      deliveryCharges: deliveryCharge,
    };

    const path = `${envVariables.apiBaseUrl}/orders/ordertokensslcommerz`;
    try {
      const { data } = await http.postRequest(path, reqData, config);
      dispatch({
        type: ActionTypes.PURCHASE_SSL_SUCCESS,
        payload: data.gatewayPageURL,
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.PURCHASE_SSL_FAILED });
      dispatch({
        type: ActionTypes.SET_ALERT,
        payload: {
          alertType: AlertTypeEnum.BackendAlert,
          message: error.formatMessage(err) as string,
        },
      });
    }
  };

export const purchaseWithStripe =
  (
    totalAmount: number,
    currency: string,
    countryCode: string,
    userInfo: IShippingUserInput,
    userAddressInfo: ICheckoutAddressInput,
    mobile: string,
    token: string,
    deliveryZoneInfo: IDeliveryZone
  ) =>
  async (dispatch: Dispatch<ICheckoutAction | IAlertAction>) => {
    dispatch({
      type: ActionTypes.PURCHASE_STRIPE_IN_PROGRESS,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const deliveryCharge =
      currency === 'BDT'
        ? deliveryZoneInfo.chargeInBDT
        : currency === 'GBP'
        ? deliveryZoneInfo.chargeInGBP
        : deliveryZoneInfo.chargeInUSD;

    const deliveryChargeId =
      currency === 'BDT'
        ? deliveryZoneInfo.stripeBDTId
        : currency === 'GBP'
        ? deliveryZoneInfo.stripeGBPId
        : deliveryZoneInfo.stripeUSDId;

    let reqData: any = {
      totalAmount: `${totalAmount + deliveryCharge}`,
      currency,
      cusName: `${userInfo.firstName} ${userInfo.lastName}`,
      cusEmail: userInfo.email,
      cusAdd1: userAddressInfo.billingAddress1,
      cusAdd2: userAddressInfo.billingAddress2,
      cusCity: userAddressInfo.billingCity,
      cusPostcode: userAddressInfo.billingPostCode,
      cusCountry: countryCode,
      cusPhone: mobile,
      shipName: `${userInfo.firstName} ${userInfo.lastName}`,
      shipAdd1: userAddressInfo.shippingAddress1,
      shipAdd2: userAddressInfo.shippingAddress2,
      shipCity: userAddressInfo.shippingCity,
      shipState: userAddressInfo.shippingState,
      shipPostCode: userAddressInfo.shippingPostCode,
      shipCountry: userAddressInfo.shippingCountryCode,
      deliveryZoneCode: userAddressInfo.deliveryZoneCode,
      deliveryCharges: deliveryCharge,
      stripeShippingId: deliveryChargeId,
    };

    const path = `${envVariables.apiBaseUrl}/orders/createstripesession`;
    try {
      const { data } = await http.postRequest(path, reqData, config);
      dispatch({
        type: ActionTypes.PURCHASE_STRIPE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.PURCHASE_STRIPE_FAILED });
      dispatch({
        type: ActionTypes.SET_ALERT,
        payload: {
          alertType: AlertTypeEnum.BackendAlert,
          message: error.formatMessage(err) as string,
        },
      });
    }
  };

export const setStripeSession =
  (session: string | null) => async (dispatch: Dispatch<ICheckoutAction>) => {
    dispatch({
      type: ActionTypes.SET_STRIPE_SESSION,
      payload: session,
    });
  };
