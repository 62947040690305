import { AlertTypeEnum } from '../../customTypes';
import { IOrderHistory, IOrderItem } from '../../models';
import { IOrderAction } from '../actions/interface';
import { ICheckoutAction } from '../actions/interface';
import { IAlertAction } from '../actions/interface';
import { ActionTypes } from '../types';

interface IAlertReducer {
  isOpen: boolean;
  message: string;
  type: AlertTypeEnum | null;
}

const initialState = {
  isOpen: false,
  message: '',
  type: null,
};

export default (
  state: IAlertReducer = initialState,
  action: IAlertAction
): IAlertReducer => {
  switch (action.type) {
    case ActionTypes.SET_ALERT:
      return {
        ...state,
        isOpen: true,
        message: action.payload.message,
        type: action.payload.alertType,
      };
    case ActionTypes.RESET_ALERT:
      return {
        ...state,
        isOpen: false,
        message: '',
        type: null,
      };
    default:
      return state;
  }
};
