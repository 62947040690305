import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { DataLoading } from '../components/common';
import { useStoreState } from '../hooks';
import { constants, storage } from '../utility';

interface IAppRouteProps {
  component: React.FC;
  layout?: React.FC;
  isProtectedRoute: boolean;
  path: string;
}

export const AppRoute: React.FC<IAppRouteProps> = ({
  component: Component,
  layout: Layout,
  isProtectedRoute,
  path,
}) => {
  const {
    media: { ribbonAudios, ribbonBooks, ribbonVideos },
    auth: { countries, isAuthenticated, deliveryZones },
  } = useStoreState();

  return (
    <Route
      path={path}
      exact
      render={(props) => {
        if (
          isProtectedRoute &&
          !storage.getItem(constants.tokenStorageKey) &&
          !isAuthenticated
        ) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        if (
          !ribbonAudios ||
          !ribbonBooks ||
          !ribbonVideos ||
          !countries ||
          !deliveryZones
        ) {
          return <DataLoading />;
        }

        if (Layout) {
          return (
            <Layout>
              <Component />
            </Layout>
          );
        } else {
          return <Component />;
        }
      }}
    />
  );
};
