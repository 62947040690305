import { Dispatch } from 'redux';
import { ISearchItem } from '../../models';
import { constants, envVariables, http } from '../../utility';
import { ActionTypes } from '../types';
import { ISearchActions } from './interface';

export const searchForMedia =
  (media: string, query: string, page: number) =>
  async (dispatch: Dispatch<ISearchActions>) => {
    dispatch({
      type: ActionTypes.SEARCH_IN_PROGRESS,
    });

    dispatch({
      type: ActionTypes.SET_SEARCH_PAGE_NUMBER,
      payload: page,
    });

    const path = `${envVariables.apiBaseUrl}/${
      media === 'Book' ? 'books' : media === 'Audio' ? 'audios' : 'videos'
    }/search`;

    const offset = (page - 1) * constants.searchLimitPerPage;

    const config = {
      params: {
        condition: query,
        offset,
        limit: constants.searchLimitPerPage,
      },
    };

    try {
      const { data } = await http.getRequest(path, config);

      dispatch({
        type: ActionTypes.SEARCH_SUCCESS,
        payload: {
          query,
          items: data.list.map((item: any): ISearchItem => {
            return {
              id: item.id,
              title: item.title,
              coverArt: `${
                media === 'Book'
                  ? envVariables.awsBooksBaseUrl
                  : media === 'Audio'
                  ? envVariables.awsAudioBaseUrl
                  : envVariables.awsVideoBaseUrl
              }/${item.coverImage}`,
              contentType: item.contentTypeName,
              media,
            };
          }),
          totalResult: data.count,
        },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.SEARCH_FAILED,
      });
    }
  };

export const clearSearch = () => async (dispatch: Dispatch<ISearchActions>) => {
  dispatch({
    type: ActionTypes.CLEAR_SEARCH,
  });
};
