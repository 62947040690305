import { Dispatch } from 'redux';
import { ActionTypes } from '../types';
import { ILibraryAction } from './interface';
import { constants, envVariables, http } from '../../utility';
import { ILibraryItem } from '../../models';
import { IAuthorItem, IGenreItem } from '../../customTypes';

export const getLibraryItems =
  (token: string) => async (dispatch: Dispatch<ILibraryAction>) => {
    dispatch({
      type: ActionTypes.GET_LIBRARY_ITEM_IN_PROGRESS,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const libraryBookPath = `${envVariables.apiBaseUrl}/customerlibrary/getbookbycustomer`;
    const libraryAudioPath = `${envVariables.apiBaseUrl}/customerlibrary/getaudiobycustomer`;
    const libraryVideoPath = `${envVariables.apiBaseUrl}/customerlibrary/getvideobycustomer`;
    try {
      const libraryBooks = await http.getRequest(libraryBookPath, config);
      const libraryAudio = await http.getRequest(libraryAudioPath, config);
      const libraryVideo = await http.getRequest(libraryVideoPath, config);

      dispatch({
        type: ActionTypes.GET_LIBRARY_ITEM_SUCCESS,
        payload: {
          libraryBooks: libraryBooks.data.map((item: any): ILibraryItem => {
            return {
              id: item.bookId,
              libraryId: item.id,
              contentType: item.contentTypeName,
              title: item.bookTitle,
              author: item.authorItems.map((author: any): IAuthorItem => {
                return {
                  id: author.id,
                  name: author.name,
                  role: author.authorRole,
                };
              }),
              coverArt: `${envVariables.awsBooksBaseUrl}/${item.coverImage}`,
              genre: item.genreItems.map((genre: any): IGenreItem => {
                return {
                  id: genre.id,
                  name: genre.englishName
                    ? genre.englishName
                    : genre.description,
                };
              }),
              description: item.shortDescription,
              media: 'Book',
              priceInBDT: item.priceBdt,
              priceInGBP: item.priceGbp,
              priceInUSD: item.priceUsd,
              token: item.point,
              isEContent: item.isEContent,
              isPaid: item.isPaid,
              discountInBDT: item.discountBdt,
              discountInGBP: item.discountGbp,
              discountInUSD: item.discountUsd,
            };
          }),
          libraryAudios: libraryAudio.data.map((item: any): ILibraryItem => {
            return {
              id: item.audioId,
              libraryId: item.id,
              contentType: item.contentTypeName,
              title: item.audioTitle,
              author: item.authorItems.map((author: any): IAuthorItem => {
                return {
                  id: author.id,
                  name: author.name,
                  role: author.authorRole,
                };
              }),
              coverArt: `${envVariables.awsAudioBaseUrl}/${item.coverImage}`,
              genre: item.genreItems.map((genre: any): IGenreItem => {
                return {
                  id: genre.id,
                  name: genre.englishName
                    ? genre.englishName
                    : genre.description,
                };
              }),
              description: item.shortDescription,
              media: 'Audio',
              priceInBDT: item.priceBdt,
              priceInGBP: item.priceGbp,
              priceInUSD: item.priceUsd,
              token: item.point,
              isEContent: item.isEContent,
              isPaid: item.isPaid,
              discountInBDT: item.discountBdt,
              discountInGBP: item.discountGbp,
              discountInUSD: item.discountUsd,
            };
          }),
          libraryVideos: libraryVideo.data.map((item: any): ILibraryItem => {
            return {
              id: item.videoId,
              libraryId: item.id,
              contentType: item.contentTypeName,
              title: item.videoTitle,
              author: item.authorItems.map((author: any): IAuthorItem => {
                return {
                  id: author.id,
                  name: author.name,
                  role: author.authorRole,
                };
              }),
              coverArt: `${envVariables.awsVideoBaseUrl}/${item.coverImage}`,
              genre: item.genreItems.map((genre: any): IGenreItem => {
                return {
                  id: genre.id,
                  name: genre.englishName
                    ? genre.englishName
                    : genre.description,
                };
              }),
              description: item.shortDescription,
              media: 'Video',
              priceInBDT: item.priceBdt,
              priceInGBP: item.priceGbp,
              priceInUSD: item.priceUsd,
              token: item.point,
              isEContent: item.isEContent,
              isPaid: item.isPaid,
              discountInBDT: item.discountBdt,
              discountInGBP: item.discountGbp,
              discountInUSD: item.discountUsd,
            };
          }),
        },
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.GET_LIBRARY_ITEM_FAILED });
    }
  };

export const addItemToLibrary =
  (token: string, userId: string, itemId: number, itemMedia: string) =>
  async (dispatch: Dispatch<ILibraryAction>) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({
      type: ActionTypes.ADD_ITEM_TO_LIBRARY_IN_PROGRESS,
    });
    const path = `${envVariables.apiBaseUrl}/customerlibrary`;

    let data: any = {
      customerId: userId,
    };

    if (itemMedia === 'Book') {
      data = {
        ...data,
        bookId: itemId,
      };
    } else if (itemMedia === 'Video') {
      data = {
        ...data,
        videoId: itemId,
      };
    } else {
      data = {
        ...data,
        audioId: itemId,
      };
    }

    try {
      await http.postRequest(path, data, config);
      dispatch({
        type: ActionTypes.ADD_ITEM_TO_LIBRARY_SUCCESS,
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({
        type: ActionTypes.ADD_ITEM_TO_LIBRARY_FAILED,
      });
    }
  };

export const deleteItemFromLibrary =
  (id: number, media: string, token: string) =>
  async (dispatch: Dispatch<ILibraryAction>) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch({ type: ActionTypes.DELETE_LIBRARY_ITEM_IN_PROGRESS });
    const path = `${envVariables.apiBaseUrl}/customerlibrary/${id}`;

    try {
      await http.deleteRequest(path, config);

      dispatch({
        type: ActionTypes.DELETE_LIBRARY_ITEM_SUCCESS,
        payload: {
          media,
          itemId: id,
        },
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.DELETE_LIBRARY_ITEM_FAILED });
    }
  };

export const resetAddToLibrarySuccess =
  () => async (dispatch: Dispatch<ILibraryAction>) => {
    dispatch({
      type: ActionTypes.RESET_ADD_TO_LIBRARY_SUCCESS,
    });
  };
