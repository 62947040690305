import React, { useEffect } from 'react';
import './index.scss';
import cholishnuDarkVerticalFullLogo from '../../assets/images/cholishnuDarkVerticalFullLogo.png';
import LoginForm from './LoginForm';
import { Alert } from 'react-bootstrap';
import { useAuth, useRouter, useStoreState } from '../../hooks';

const Login: React.FC = () => {
  const { navigateTo } = useRouter();
  const { signIn } = useAuth();
  const {
    auth: { isAuthInProgress, isAuthenticated, authError },
  } = useStoreState();

  useEffect(() => {
    if (isAuthenticated) {
      navigateTo('/');
    }
  }, [isAuthenticated]);
  return (
    <div className="login">
      <div className="login__logo">
        <img src={cholishnuDarkVerticalFullLogo} />
      </div>
      <div className="login__title">
        <h3>Sign in</h3>
      </div>
      <div className="login__form">
        {authError && <Alert variant="danger">{authError}</Alert>}
        <LoginForm
          onSubmit={signIn}
          isLoading={isAuthInProgress}
          onNavigate={navigateTo}
        />
      </div>
    </div>
  );
};

export default Login;
