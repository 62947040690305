import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

interface IChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChangePassword: (
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string
  ) => void;
  error: string | null;
  onErrorReset: (err: string | null) => void;
  isLoading: boolean;
}

interface IFormInput {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordModal: React.FC<IChangePasswordModalProps> = ({
  isOpen,
  onClose,
  onChangePassword,
  error,
  onErrorReset,
  isLoading,
}) => {
  const [formInput, setFormInput] = useState<IFormInput>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onErrorReset(null);
    setFormInput({
      ...formInput,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          New password must be atleast 8 characters, with at least one
          uppercase, one lowercase and a number
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Old Password"
          name="oldPassword"
          value={formInput.oldPassword}
          onChange={handleInputChange}
          type="password"
          required
          fullWidth
        />
        <TextField
          margin="dense"
          id="name"
          label="New Password"
          name="newPassword"
          value={formInput.newPassword}
          onChange={handleInputChange}
          type="password"
          required
          fullWidth
        />
        <TextField
          margin="dense"
          id="name"
          label="Confirm Password"
          name="confirmPassword"
          value={formInput.confirmPassword}
          onChange={handleInputChange}
          type="password"
          required
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          Cancel
        </Button>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            onClick={() => {
              onChangePassword(
                formInput.oldPassword,
                formInput.newPassword,
                formInput.confirmPassword
              );
            }}
            color="primary"
          >
            Change
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordModal;
