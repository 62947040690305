import React from 'react';
import Login from '../components/login';
import Books from '../components/books';
import Videos from '../components/videos';
import Audios from '../components/audios';
import Home from '../components/home';
import Register from '../components/register';
import ForgetPassword from '../components/forgetPassword';
import BookById from '../components/bookById';
import VideoById from '../components/videoById';
import AudioById from '../components/audioById';
import SearchMedia from '../components/searchMedia';
import Profile from '../components/profile';
import Library from '../components/library';
import Orders from '../components/orders';
import OrderById from '../components/orderById';
import Cart from '../components/cart';
import Shipping from '../components/shipping';
import PaymentSuccess from '../components/paymentSuccess';
import PaymentFailed from '../components/paymentFailed';
import About from '../components/about';
import Contact from '../components/contact';

export type IRouteItem = {
  path: string;
  component: React.FC;
};
export type IRoute = IRouteItem[];

export const publicRoutes: IRoute = [
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/forget-password', component: ForgetPassword },
  { path: '/books/:id', component: BookById },
  { path: '/books', component: Books },
  { path: '/audios/:id', component: AudioById },
  { path: '/audios', component: Audios },
  { path: '/videos/:id', component: VideoById },
  { path: '/videos', component: Videos },
  { path: '/search/:media', component: SearchMedia },
  { path: '/payment-success', component: PaymentSuccess },
  { path: '/payment-failed', component: PaymentFailed },
  { path: '/about', component: About },
  { path: '/contact', component: Contact },
];

export const privateRoute: IRoute = [
  { path: '/profile', component: Profile },
  { path: '/library', component: Library },
  { path: '/orders/:id', component: OrderById },
  { path: '/orders', component: Orders },
  { path: '/cart', component: Cart },
  { path: '/shipping', component: Shipping },
];
