import React from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { ICartItem } from '../../models';
import './cartItems.scss';
import IndividualItem from './IndividualItem';

interface ICartItemsProps {
  cartItems: ICartItem[];
  onRemoveItem: (id: number) => void;
  onUpdateQuantity: (item: ICartItem, shouldIncrease?: boolean) => void;
  onClearCart: () => void;
}

const CartItems: React.FC<ICartItemsProps> = ({
  cartItems,
  onRemoveItem,
  onUpdateQuantity,
  onClearCart,
}) => {
  return (
    <Card className="cartItems">
      <Card.Body>
        {cartItems.map((item) => {
          return (
            <div key={item.itemId}>
              <IndividualItem
                onUpdateQuantity={onUpdateQuantity}
                onRemoveItem={onRemoveItem}
                item={item}
              />
            </div>
          );
        })}
        <Button
          onClick={onClearCart}
          className="cartItems__button"
          variant="outline-danger"
        >
          Clear Cart
        </Button>
      </Card.Body>
    </Card>
  );
};

export default CartItems;
