import { Dispatch } from 'redux';
import { IAuthAction } from './interface';
import {
  IEditProfileFormInput,
  ILoginFormData,
  IRegisterFormData,
  RegistrationInputEnum,
} from '../../customTypes';
import { ActionTypes } from '../types';
import { http, constants, envVariables, error, storage } from '../../utility';
import { ICountry, IDeliveryZone } from '../../models';

export const getCountryInfo = () => async (dispatch: Dispatch<IAuthAction>) => {
  const path = `${envVariables.apiBaseUrl}/lookup/getcountries`;

  try {
    const { data } = await http.getRequest(path);

    dispatch({
      type: ActionTypes.GET_COUNTRY_INFO,
      payload: data.map((item: any): ICountry => {
        return {
          code: item.code,
          name: item.name,
          currency: item.currencyCode,
          vat: item.vat,
        };
      }),
    });
  } catch (err) {
    console.log(err.message);
  }
};

export const getDeliveryZones =
  () => async (dispatch: Dispatch<IAuthAction>) => {
    const path = `${envVariables.apiBaseUrl}/lookup/getDeliveryZones`;

    try {
      const { data } = await http.getRequest(path);

      dispatch({
        type: ActionTypes.GET_DELIVERY_ZONE,
        payload: data.map((item: any): IDeliveryZone => {
          return {
            id: item.id,
            zoneCode: item.zoneCode,
            zoneName: item.zoneName,
            chargeInBDT: item.chargeBdt,
            chargeInGBP: item.chargeGbp,
            chargeInUSD: item.chargeUsd,
            stripeBDTId: item.stripeBdtId,
            stripeGBPId: item.stripeGbpId,
            stripeUSDId: item.stripeUsdId,
          };
        }),
      });
    } catch (err) {
      console.log(err.message);
    }
  };

export const getUserCountryInfo =
  (userCurrentCountryCode: string, userRegisteredCountryCode: string) =>
  async (dispatch: Dispatch<IAuthAction>) => {
    const currentCountrypath = `${envVariables.apiBaseUrl}/lookup/getcurrencybycountrycode/${userCurrentCountryCode}`;
    const registeredCountrypath = `${envVariables.apiBaseUrl}/lookup/getcurrencybycountrycode/${userRegisteredCountryCode}`;

    try {
      const currentCountry = await http.getRequest(currentCountrypath);
      const registeredCountry = await http.getRequest(registeredCountrypath);

      dispatch({
        type: ActionTypes.GET_USER_COUNTRY_INFO,
        payload: {
          currentCountryName: currentCountry.data.name,
          registeredCountryName: registeredCountry.data.name,
          currency: registeredCountry.data.currencyCode,
        },
      });
    } catch (err) {
      console.log(err.message);
    }
  };

export const setUserVat =
  (vat: number) => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.SET_USER_VAT,
      payload: vat,
    });
  };

export const getUser =
  (token: string) => async (dispatch: Dispatch<IAuthAction>) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const path = `${envVariables.apiBaseUrl}/customers/getcustomer`;

    try {
      const { data } = await http.getRequest(path, config);

      dispatch({
        type: ActionTypes.GET_USER_SUCCESS,
        payload: {
          id: data.id,
          title: data.title,
          firstName: data.firstName,
          lastName: data.lastName,
          subscription: data.subscriptionLevel,
          token: data.point,
          dob: data.dob,
          profileImage: data.profileImage,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          postCode: data.postalCode,
          email: data.email,
          mobile: data.mobile,
          registeredCountry: data.countryCode,
          currentCountry: data.currentLocation,
        },
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: ActionTypes.GET_USER_FAILED,
      });

      storage.removeItem(constants.tokenStorageKey);
    }
  };

export const login =
  (formData: ILoginFormData) => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.LOGIN_IN_PROGRESS,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const reqData = {
      email: formData.username,
      password: formData.password,
    };

    const path = `${envVariables.apiBaseUrl}/identity/token`;

    try {
      const { data } = await http.postRequest(path, reqData, config);

      storage.saveItem(constants.tokenStorageKey, data.token);

      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
        payload: data.token,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.LOGIN_FAILED,
        payload: error.formatMessage(err) as string,
      });

      storage.removeItem(constants.tokenStorageKey);
    }
  };

export const register =
  (formData: IRegisterFormData) => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.REGISTER_IN_PROGRESS,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const reqData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      countryCode: formData.country,
      dob: formData.dob,
      email: formData.email,
      password: formData.password,
      mobile: formData.mobile,
    };

    const path = `${envVariables.apiBaseUrl}/customers/customer`;

    try {
      const { data } = await http.postRequest(path, reqData, config);

      dispatch({
        type: ActionTypes.REGISTER_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.REGISTER_FAILED,
        payload: error.formatMessage(err) as string,
      });
    }
  };

export const setRegistrationdError =
  (error: string | null, errorField?: RegistrationInputEnum) =>
  async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.SET_REGISTRATION_ERROR,
      payload: error ? { error: error, errorField: errorField } : null,
    });
  };

export const forgetPassword =
  (email: string) => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.FORGET_EMAIL_SENDING_IN_PROGRESS,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const reqData = {
      email,
    };

    const path = `${envVariables.apiBaseUrl}/identity/forgot-password`;

    try {
      const { data } = await http.postRequest(path, reqData, config);

      dispatch({
        type: ActionTypes.FORGET_EMAIL_SENDING_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.FORGET_EMAIL_SENDING_FAILED,
        payload: error.formatMessage(err) as string,
      });
    }
  };

export const setTokenFromStorage =
  () => async (dispatch: Dispatch<IAuthAction>) => {
    const storedToken = storage.getItem(constants.tokenStorageKey);

    if (!storedToken) return;

    dispatch({
      type: ActionTypes.SET_TOKEN,
      payload: storedToken,
    });
  };

export const logout = () => async (dispatch: Dispatch<IAuthAction>) => {
  storage.removeItem(constants.tokenStorageKey);
  dispatch({
    type: ActionTypes.LOGOUT,
  });
};

export const changePassword =
  (oldPassword: string, newPassword: string, token: string) =>
  async (dispatch: Dispatch<IAuthAction>) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const reqBody = {
      oldPassword,
      newPassword,
    };

    const path = `${envVariables.apiBaseUrl}/identity/change-password`;

    dispatch({ type: ActionTypes.CHANGE_PASSWORD_IN_PROGRESS });

    try {
      const { data } = await http.postRequest(path, reqBody, config);
      dispatch({ type: ActionTypes.CHANGE_PASSWORD_SUCCESS });
    } catch (err) {
      dispatch({
        type: ActionTypes.CHANGE_PASSWORD_FAILED,
        payload: error.formatMessage(err) as string,
      });
    }
  };

export const toggleChangePasswordModal =
  (isOpen: boolean) => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.TOGGLE_CHANGE_PASSWORD_MODAL,
      payload: isOpen,
    });
  };

export const setChangePasswordError =
  (error: string | null) => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.SET_CHANGE_PASSWORD_ERROR,
      payload: error,
    });
  };

export const resetPasswordChangeSuccess =
  () => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.RESET_CHANGE_PASSWORD_SUCCESS,
    });
  };

export const updateProfile =
  (
    token: string,
    userInfo: IEditProfileFormInput,
    mobile: string,
    dob: Date,
    currentLocation: string
  ) =>
  async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.UPDATE_PROFILE_IN_PROGRESS,
    });

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const reqData = {
      title: userInfo.title,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      dob,
      mobile,
      address1: userInfo.address1 ? userInfo.address1 : null,
      address2: userInfo.address2 ? userInfo.address2 : null,
      city: userInfo.city ? userInfo.city : null,
      postalCode: userInfo.postCode ? userInfo.postCode : null,
      state: userInfo.state ? userInfo.state : null,
      currentLocation,
    };

    const path = `${envVariables.apiBaseUrl}/customers`;

    try {
      const { data } = await http.putRequest(path, reqData, config);

      dispatch({
        type: ActionTypes.UPDATE_PROFILE_SUCCESS,
        payload: {
          id: data.id,
          title: data.title,
          firstName: data.firstName,
          lastName: data.lastName,
          subscription: data.subscriptionLevel,
          token: data.point,
          dob: data.dob,
          profileImage: data.profileImage,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          state: data.state,
          postCode: data.postalCode,
          email: data.email,
          mobile: data.mobile,
          registeredCountry: data.countryCode,
          currentCountry: data.currentLocation,
        },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.UPDATE_PROFILE_FAILED,
      });
    }
  };

export const resetUpdateProfileSuccess =
  () => async (dispatch: Dispatch<IAuthAction>) => {
    dispatch({
      type: ActionTypes.RESET_UPDATE_PROFILE_SUCCESS,
    });
  };
