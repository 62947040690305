import React from 'react';
import { AlertTypeEnum } from '../../customTypes';
import { useAlert, useStoreState } from '../../hooks';
import CustomAlert from './CustomAlert';

const BackendAlert: React.FC = () => {
  const {
    alert: { type: alertType, isOpen: isAlertOpen },
  } = useStoreState();
  const { closeAlert } = useAlert();
  return (
    <>
      {alertType === AlertTypeEnum.BackendAlert && (
        <CustomAlert
          title="Failed Request"
          isOpen={isAlertOpen}
          onHideAlert={closeAlert}
          onNoButtonPress={closeAlert}
          noButtonText="Ok"
          onYesButtonPress={() => {}}
        />
      )}
    </>
  );
};

export default BackendAlert;
