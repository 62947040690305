import { ISearchItem } from '../../models';
import { ISearchActions } from '../actions/interface';
import { ActionTypes } from '../types';

interface ISearchReducer {
  searchedMedia: ISearchItem[] | null;
  totalResult: number;
  isSearching: boolean;
  hasSearchFailed: boolean;
  searchQuery: string;
  currentPageNumber: number;
}

const initialState = {
  searchedMedia: null,
  totalResult: 0,
  isSearching: false,
  hasSearchFailed: false,
  searchQuery: '',
  currentPageNumber: 1,
};

export default (
  state: ISearchReducer = initialState,
  action: ISearchActions
): ISearchReducer => {
  switch (action.type) {
    case ActionTypes.SEARCH_IN_PROGRESS:
      return {
        ...state,
        isSearching: true,
        searchedMedia: [],
        totalResult: 0,
        hasSearchFailed: false,
      };
    case ActionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchedMedia: action.payload.items,
        totalResult: action.payload.totalResult,
        searchQuery: action.payload.query,
        hasSearchFailed: false,
      };
    case ActionTypes.SEARCH_FAILED:
      return {
        ...state,
        isSearching: false,
        searchedMedia: null,
        totalResult: 0,
        searchQuery: '',
        hasSearchFailed: true,
      };
    case ActionTypes.CLEAR_SEARCH:
      return {
        ...state,
        searchedMedia: null,
        totalResult: 0,
        searchQuery: '',
        currentPageNumber: 1,
      };
    case ActionTypes.SET_SEARCH_PAGE_NUMBER:
      return {
        ...state,
        currentPageNumber: action.payload,
      };
    default:
      return state;
  }
};
