import { combineReducers } from 'redux';
import alertReducer from './alertReducer';
import authReducer from './authReducer';
import cartReducer from './cartReducer';
import checkoutReducer from './checkoutReducer';
import libraryReducer from './libraryReducer';
import mediaReducer from './mediaReducer';
import orderReducer from './orderReducer';
import searchReducer from './searchReducer';

const reducers = combineReducers({
  auth: authReducer,
  media: mediaReducer,
  search: searchReducer,
  library: libraryReducer,
  order: orderReducer,
  cart: cartReducer,
  checkout: checkoutReducer,
  alert: alertReducer,
});

export default reducers;
export type RootState = ReturnType<typeof reducers>;
