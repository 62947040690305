import moment from 'moment';
import { constants } from './constants';

export const date = {
  verifyAge: (dob: Date) => {
    return moment(dob).isBefore(moment().subtract(13, 'years'));
  },

  shortFormat: (dob: Date) => {
    return moment(dob).format(constants.shortDateFormat);
  },

  orderHistoryFormat: (purchaseAt: Date) => {
    return moment(purchaseAt).format(constants.orderDateFormat);
  },
};
