import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import aboutPageImage from '../../assets/images/aboutPage.jpg';
import './index.scss';

const About: React.FC = () => {
  return (
    <Container className="about">
      <Row>
        <Col className="about__imageContainer">
          <div>
            <Image
              alt="about page image"
              className="about__image"
              src={aboutPageImage}
              fluid
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="about__titleContainer">
            <h1>We are Cholishnu</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="about__paraContainer">
            <p className="about__para">
              At Cholishnu, we are building an online platform with four
              modules: books, audio, video and events. In addition to buying
              paper books, users will instantly buy ebook and audiobook for
              reading and listening from Cholishnu app. Our aim is to offer book
              lovers a complete reading experience so that they can read or
              listen to any books, any time and in the medium they want.
              Moreover, Cholishnu app will allow our users to listen to a
              curated collection of songs, interviews and podcasts. We are also
              producing high-quality videos on a wide range of topics covering
              arts, culture, literature, science and travel. In the event
              section of the app, the user will be easily browsing and book
              interesting cultural and social events within seconds.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
