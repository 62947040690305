import { RegistrationInputEnum } from '../../customTypes';
import { ICountry, IDeliveryZone, IUser } from '../../models';
import { IAuthAction } from '../actions/interface';
import { ActionTypes } from '../types';

interface IRegistrationError {
  errorMessage: string;
  errorField?: RegistrationInputEnum;
}

interface IAuthReducer {
  countries: ICountry[] | null;
  deliveryZones: IDeliveryZone[] | null;
  token: string;
  user: IUser | null;
  userRegisteredCountryName: string | null;
  userCurrentCountryName: string | null;
  userCurrency: string;
  userVat: number;
  authError: string | null;
  isAuthInProgress: boolean;
  isAuthenticated: boolean;
  isRegistrationInProgress: boolean;
  isRegistrationSuccessfull: boolean;
  registrationError: IRegistrationError | null;
  isForgetEmailSendingInProgress: boolean;
  isForgetEmailSendingSuccessfull: boolean;
  forgetEmailSendingError: string | null;
  isChangePasswordModalOpen: boolean;
  isChangePasswordInProgress: boolean;
  isChangePasswordSuccessfull: boolean;
  isUpdateProfileInProgress: boolean;
  isUpdateProfileSuccessfull: boolean;
  hasUpdateProfileFailed: boolean;
  changePasswordError: string | null;
}

const initialState: IAuthReducer = {
  countries: null,
  deliveryZones: null,
  token: '',
  user: null,
  userRegisteredCountryName: null,
  userCurrentCountryName: null,
  userVat: 0,
  userCurrency: 'BDT',
  authError: null,
  isAuthInProgress: false,
  isAuthenticated: false,
  isForgetEmailSendingInProgress: false,
  isForgetEmailSendingSuccessfull: false,
  isRegistrationInProgress: false,
  isRegistrationSuccessfull: false,
  registrationError: null,
  forgetEmailSendingError: null,
  isChangePasswordModalOpen: false,
  isChangePasswordInProgress: false,
  isChangePasswordSuccessfull: false,
  changePasswordError: null,
  isUpdateProfileInProgress: false,
  isUpdateProfileSuccessfull: false,
  hasUpdateProfileFailed: false,
};

export default (
  state: IAuthReducer = initialState,
  action: IAuthAction
): IAuthReducer => {
  switch (action.type) {
    case ActionTypes.LOGIN_IN_PROGRESS:
      return {
        ...state,
        isAuthInProgress: true,
        authError: null,
        isAuthenticated: false,
        token: '',
      };
    case ActionTypes.SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case ActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case ActionTypes.GET_USER_FAILED:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        token: '',
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthInProgress: false,
        authError: null,
        isAuthenticated: true,
        token: action.payload,
      };
    case ActionTypes.LOGIN_FAILED:
      return {
        ...state,
        isAuthInProgress: false,
        authError: action.payload,
        isAuthenticated: false,
        token: '',
      };
    case ActionTypes.REGISTER_IN_PROGRESS:
      return {
        ...state,
        isRegistrationInProgress: true,
        isRegistrationSuccessfull: false,
        registrationError: null,
      };
    case ActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        isRegistrationInProgress: false,
        isRegistrationSuccessfull: true,
        registrationError: null,
      };
    case ActionTypes.REGISTER_FAILED:
      return {
        ...state,
        isRegistrationInProgress: false,
        isRegistrationSuccessfull: false,
        registrationError: {
          errorMessage: action.payload,
        },
      };
    case ActionTypes.SET_REGISTRATION_ERROR:
      return {
        ...state,
        isRegistrationInProgress: false,
        isRegistrationSuccessfull: false,
        registrationError: action.payload
          ? {
              errorMessage: action.payload.error,
              errorField: action.payload.errorField,
            }
          : null,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        isAuthInProgress: false,
        authError: null,
        isAuthenticated: false,
        token: '',
      };
    case ActionTypes.FORGET_EMAIL_SENDING_IN_PROGRESS:
      return {
        ...state,
        isForgetEmailSendingInProgress: true,
        isForgetEmailSendingSuccessfull: false,
        forgetEmailSendingError: null,
      };
    case ActionTypes.FORGET_EMAIL_SENDING_SUCCESS:
      return {
        ...state,
        isForgetEmailSendingInProgress: false,
        isForgetEmailSendingSuccessfull: true,
        forgetEmailSendingError: null,
      };
    case ActionTypes.FORGET_EMAIL_SENDING_FAILED:
      return {
        ...state,
        isForgetEmailSendingInProgress: false,
        isForgetEmailSendingSuccessfull: false,
        forgetEmailSendingError: action.payload,
      };
    case ActionTypes.TOGGLE_CHANGE_PASSWORD_MODAL:
      return {
        ...state,
        isChangePasswordModalOpen: action.payload,
      };
    case ActionTypes.CHANGE_PASSWORD_IN_PROGRESS:
      return {
        ...state,
        changePasswordError: null,
        isChangePasswordInProgress: true,
        isChangePasswordSuccessfull: false,
      };
    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePasswordModalOpen: false,
        isChangePasswordInProgress: false,
        isChangePasswordSuccessfull: true,
        changePasswordError: null,
      };
    case ActionTypes.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        isChangePasswordInProgress: false,
        isChangePasswordSuccessfull: false,
        changePasswordError: action.payload,
      };
    case ActionTypes.SET_CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordError: action.payload,
      };
    case ActionTypes.RESET_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangePasswordInProgress: false,
        isChangePasswordSuccessfull: false,
        changePasswordError: null,
      };
    case ActionTypes.GET_COUNTRY_INFO:
      return {
        ...state,
        countries: action.payload,
      };
    case ActionTypes.GET_USER_COUNTRY_INFO:
      return {
        ...state,
        userRegisteredCountryName: action.payload.registeredCountryName,
        userCurrentCountryName: action.payload.currentCountryName,
        userCurrency: action.payload.currency,
      };
    case ActionTypes.SET_USER_VAT:
      return {
        ...state,
        userVat: action.payload,
      };
    case ActionTypes.UPDATE_PROFILE_IN_PROGRESS:
      return {
        ...state,
        isUpdateProfileInProgress: true,
        isUpdateProfileSuccessfull: false,
        hasUpdateProfileFailed: false,
      };
    case ActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isUpdateProfileInProgress: false,
        isUpdateProfileSuccessfull: true,
        hasUpdateProfileFailed: false,
      };
    case ActionTypes.UPDATE_PROFILE_FAILED:
      return {
        ...state,
        isUpdateProfileInProgress: false,
        isUpdateProfileSuccessfull: false,
        hasUpdateProfileFailed: true,
      };
    case ActionTypes.RESET_UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isUpdateProfileInProgress: false,
        isUpdateProfileSuccessfull: false,
        hasUpdateProfileFailed: false,
      };
    case ActionTypes.GET_DELIVERY_ZONE:
      return {
        ...state,
        deliveryZones: action.payload,
      };
    default:
      return state;
  }
};
