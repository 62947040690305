import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IOrderHistory } from '../../models';
import { date, price } from '../../utility';
import './orderHistoryItem.scss';

interface IOrderHistoryItemProps {
  order: IOrderHistory;
}

const OrderHistoryItem: React.FC<IOrderHistoryItemProps> = ({ order }) => {
  return (
    <Card className="orderHistoryItem">
      <Card.Body>
        <Card.Title className="orderHistoryItem__title">
          Order #{order.id}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {date.orderHistoryFormat(order.purchasedAt)}
        </Card.Subtitle>
        <Card.Text>
          <span className="orderHistoryItem__infoTitle">Total Amount:</span>{' '}
          {price.format(order.currency, order.totalAmount)}{' '}
          <span className="text-muted">
            (includes vat: {price.format(order.currency, order.totalVat)},
            delivery: {price.format(order.currency, order.deliveryCharge)})
          </span>
        </Card.Text>

        {order.totalDiscount > 0 && (
          <Card.Text>
            Discount:
            <span className="orderHistoryItem__discount">
              {' '}
              -{price.format(order.currency, order.totalDiscount)}{' '}
            </span>
          </Card.Text>
        )}

        <Card.Text className="orderHistoryItem__infoTitle">
          Billing Address:
        </Card.Text>
        <Card.Text className="orderHistoryItem__billingInfo">
          {order.billingAddress1 && <span>{order.billingAddress1}</span>}
          {order.billingAddress2 && <span>{order.billingAddress2}</span>}
          {order.billingCity && <span>{order.billingCity}</span>}
          {order.billingState && <span>{order.billingState}</span>}
          {order.billingPostCode && <span>{order.billingPostCode}</span>}
        </Card.Text>
        <Card.Link as={Link} to={`/orders/${order.id}`}>
          View Details
        </Card.Link>
      </Card.Body>
    </Card>
  );
};

export default OrderHistoryItem;
