import React from 'react';
import { Col, Container, Row, Image } from 'react-bootstrap';
import contactPageImage from '../../assets/images/contactPage.jpg';
import './index.scss';

const Contact: React.FC = () => {
  return (
    <Container className="contact">
      <Row>
        <Col className="contact__imageContainer">
          <div>
            <Image
              alt="contact page image"
              className="contact__image"
              src={contactPageImage}
              fluid
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="contact__titleContainer">
            <h1>Contact Us</h1>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="contact__paraContainer">
            <h3>UK Office</h3>
            <div className="contact__para">+44(0)7883512092</div>
            <div className="contact__para">hello@cholishnu.com</div>
            <div className="contact__para">104 Kirkland Avenue Ilford,</div>
            <div className="contact__para">IG5 0TN</div>
            <div className="contact__para">United Kingdom</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
