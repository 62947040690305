import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useStoreState } from '../../hooks';

interface ICustomAlertProps {
  title: string;
  isOpen: boolean;
  onHideAlert: () => void;
  noButtonText: string;
  yesButtonText?: string;
  onNoButtonPress: () => void;
  onYesButtonPress: () => void;
}

const CustomAlert: React.FC<ICustomAlertProps> = ({
  title,
  isOpen,
  onHideAlert,
  noButtonText,
  yesButtonText,
  onNoButtonPress,
  onYesButtonPress,
}) => {
  const {
    alert: { message },
  } = useStoreState();
  return (
    <Modal
      show={isOpen}
      onHide={() => onHideAlert()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {title && (
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onNoButtonPress}>
          {noButtonText}
        </Button>
        {yesButtonText && (
          <Button onClick={onYesButtonPress}>{yesButtonText}</Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomAlert;
