import { IAudio, IBook, ILibraryItem, IVideo } from '../models';
import { useStoreActions } from './useStoreActions';
import { useStoreState } from './useStoreState';

export const useLibrary = () => {
  const { deleteItemFromLibrary, resetAddToLibrarySuccess } = useStoreActions();
  const {
    auth: { token, userVat },
  } = useStoreState();

  const removeItemFromLibrary = (media: ILibraryItem) => {
    deleteItemFromLibrary(media.libraryId, media.media, token);
  };

  const resetLibrarySuccess = () => {
    resetAddToLibrarySuccess();
  };

  return { removeItemFromLibrary, resetLibrarySuccess };
};
