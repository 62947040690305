import { AxiosRequestConfig } from 'axios';
import axios from 'axios';

export const http = {
  getRequest: (path: string, config?: AxiosRequestConfig) => {
    return axios.get(path, config);
  },

  postRequest: (path: string, reqBody: any, config?: AxiosRequestConfig) => {
    return axios.post(path, reqBody, config);
  },

  putRequest: (path: string, reqBody: any, config?: AxiosRequestConfig) => {
    return axios.put(path, reqBody, config);
  },

  deleteRequest: (path: string, config?: AxiosRequestConfig) => {
    return axios.delete(path, config);
  },
};
