import { IAudio, IBook, IVideo } from '../models';

export const media = {
  getUniqueMediaGenres: (media: IBook[] | IAudio[] | IVideo[]) => {
    const allGenres = media
      .map((item: IBook | IAudio | IVideo) => item.genre)
      .flat();
    const genreNames = allGenres.map((genre) => genre.name);
    return Array.from(new Set(genreNames));
  },

  getUniqueMediaContentType: (media: IBook[] | IAudio[] | IVideo[]) => {
    const allContentType = media.map(
      (item: IBook | IAudio | IVideo) => item.contentType
    );
    return Array.from(new Set(allContentType));
  },

  getBooksByGenre: (books: IBook[], genre: string) => {
    if (genre === 'All') return books;

    return books.filter((item) => {
      const matchedGenre = item.genre.filter((genreItem) => {
        return genreItem.name === genre;
      });
      if (matchedGenre.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  },

  getAudioByGenre: (audio: IAudio[], genre: string) => {
    if (genre === 'All') return audio;

    return audio.filter((item) => {
      const matchedGenre = item.genre.filter((genreItem) => {
        return genreItem.name === genre;
      });
      if (matchedGenre.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  },

  getVideoByGenre: (video: IVideo[], genre: string) => {
    if (genre === 'All') return video;

    return video.filter((item) => {
      const matchedGenre = item.genre.filter((genreItem) => {
        return genreItem.name === genre;
      });
      if (matchedGenre.length > 0) {
        return true;
      } else {
        return false;
      }
    });
  },
};
