import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ILoginFormData } from '../../customTypes';
import './loginForm.scss';

interface ILoginFormProps {
  isLoading: boolean;
  onNavigate: (path: string) => void;
  onSubmit: (loginData: ILoginFormData) => void;
}

const LoginForm: React.FC<ILoginFormProps> = ({
  onNavigate,
  isLoading,
  onSubmit,
}) => {
  const [loginInput, setLoginInput] = useState<ILoginFormData>({
    username: '',
    password: '',
  });

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setLoginInput({
      ...loginInput,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(loginInput);
  };

  return (
    <Form onSubmit={handleSubmit} className="loginForm">
      <Form.Group controlId="formBasicEmail">
        <Form.Label className="loginForm__label">Email address</Form.Label>
        <Form.Control
          name="username"
          value={loginInput.username}
          onChange={handleInputChange}
          required
          type="email"
          placeholder="Enter email"
        />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label className="loginForm__label">Password</Form.Label>
        <Form.Control
          name="password"
          value={loginInput.password}
          onChange={handleInputChange}
          required
          type="password"
          placeholder="Password"
        />
      </Form.Group>
      {isLoading && (
        <div className="loginForm__spinner">
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <Button className="loginForm__button" variant="primary" type="submit">
          Sign in
        </Button>
      )}

      <div className="loginForm__linkTextContainer">
        <Form.Text
          className="loginForm__link"
          onClick={() => {
            onNavigate('/forget-password');
          }}
        >
          Forgot your password?
        </Form.Text>
        <Form.Text
          className="loginForm__link"
          onClick={() => {
            onNavigate('/register');
          }}
        >
          Create a new account
        </Form.Text>
      </div>
    </Form>
  );
};

export default LoginForm;
