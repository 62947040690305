import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useAuth, useStoreState } from '../../hooks';
import ChangePasswordModal from './ChangePasswordModal';
import EditProfileModal from './EditProfileModal';
import './index.scss';
import ProfileAvatarCard from './ProfileAvatarCard';
import ProfileDetailCard from './ProfileDetailCard';

const Profile: React.FC = () => {
  const {
    auth: {
      user,
      userRegisteredCountryName,
      userCurrentCountryName,
      isChangePasswordModalOpen,
      changePasswordError,
      isChangePasswordInProgress,
      isChangePasswordSuccessfull,
    },
  } = useStoreState();
  const {
    togglePasswordModal,
    setPasswordError,
    updatePassword,
    resetPasswordSuccess,
    editProfile,
  } = useAuth();

  const [successSnacbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);

  useEffect(() => {
    if (isChangePasswordSuccessfull) {
      setSuccessSnackbarOpen(true);
      resetPasswordSuccess();
    }
  }, [isChangePasswordSuccessfull]);

  return (
    <Container className="profile__profile">
      <Row className="profile__profileInfo">
        <Col lg={4}>
          {user && (
            <ProfileAvatarCard
              user={user}
              onChangePasswordButtonClick={togglePasswordModal}
            />
          )}
        </Col>
        <Col lg={8}>
          {user && userRegisteredCountryName && userCurrentCountryName && (
            <ProfileDetailCard
              user={user}
              registeredCountry={userRegisteredCountryName}
              currentCountry={userCurrentCountryName}
              onEditProfile={() => setIsEditProfileModalOpen(true)}
            />
          )}
        </Col>
      </Row>
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => {
          togglePasswordModal(false);
        }}
        onChangePassword={updatePassword}
        error={changePasswordError}
        onErrorReset={setPasswordError}
        isLoading={isChangePasswordInProgress}
      />
      <EditProfileModal
        isOpen={isEditProfileModalOpen}
        onClose={() => setIsEditProfileModalOpen(false)}
        onUpdate={editProfile}
      />
      <Snackbar
        open={successSnacbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
      >
        <Alert onClose={() => setSuccessSnackbarOpen(false)} severity="success">
          Password successfully changed!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Profile;
