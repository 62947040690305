import { Dispatch } from 'redux';
import { ActionTypes } from '../types';
import { IAlertAction, ICartAction } from './interface';
import { envVariables, error, http } from '../../utility';
import { IAudio, IBook, ICartItem, IFeatureItem, IVideo } from '../../models';
import { AlertTypeEnum } from '../../customTypes';

export const getCartData =
  (token: string) => async (dispatch: Dispatch<ICartAction>) => {
    dispatch({
      type: ActionTypes.GET_CART_DATA_IN_PROGRESS,
    });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const path = `${envVariables.apiBaseUrl}/orderbaskets/getorderbasketsbyuser`;

    try {
      const { data } = await http.getRequest(path, config);

      dispatch({
        type: ActionTypes.GET_CART_DATA_SUCCESS,
        payload: data.map((cart: any): ICartItem => {
          let itemId: number;
          let itemMedia: string;
          let itemCoverImage: string;
          let itemContentUrl: string;

          if (cart.bookId) {
            itemId = cart.bookId;
            itemMedia = 'Book';
            itemCoverImage = `${envVariables.awsBooksBaseUrl}/${cart.coverImage}`;
            itemContentUrl = `${envVariables.awsBooksBaseUrl}/${cart.contentUrl}`;
          } else if (cart.audioId) {
            itemId = cart.audioId;
            itemMedia = 'Audio';
            itemCoverImage = `${envVariables.awsAudioBaseUrl}/${cart.coverImage}`;
            itemContentUrl = `${envVariables.awsAudioBaseUrl}/${cart.contentUrl}`;
          } else {
            itemId = cart.videoId;
            itemMedia = 'Video';
            itemCoverImage = `${envVariables.awsVideoBaseUrl}/${cart.coverImage}`;
            itemContentUrl = `${envVariables.awsVideoBaseUrl}/${cart.contentUrl}`;
          }

          return {
            basketId: cart.id,
            itemId,
            itemTitle: cart.title,
            itemMedia,
            itemContentType: cart.contentType,
            itemCoverImage,
            itemContentUrl,
            totalQuantity: cart.qty,
            totalPrice: cart.amount * cart.qty,
            totalToken: cart.point * cart.qty,
            currency: cart.currencyCode,
            pricePerQty: cart.amount,
            tokenPerQty: cart.point,
            isEContent: cart.isEContent,
            discount: cart.discount,
            discountPerQty: cart.discount / cart.qty,
          };
        }),
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({ type: ActionTypes.GET_CART_DATA_FAILED });
    }
  };

export const deleteItemFromCart =
  (id: number, token: string) => async (dispatch: Dispatch<ICartAction>) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const path = `${envVariables.apiBaseUrl}/orderbaskets/${id}`;

    try {
      await http.deleteRequest(path, config);

      dispatch({
        type: ActionTypes.DELETE_CART_ITEM,
        payload: id,
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
    }
  };

export const updateCartItemQuantity =
  (
    item: ICartItem,
    token: string,
    userCountryVat: number,
    shouldIncrease?: boolean
  ) =>
  async (dispatch: Dispatch<ICartAction>) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const path = `${envVariables.apiBaseUrl}/orderbaskets`;

    let data: any = {
      id: item.basketId,
      qty: shouldIncrease ? item.totalQuantity + 1 : item.totalQuantity - 1,
      amount: item.pricePerQty,
      point: item.tokenPerQty,
      isEContent: item.isEContent,
      vat: shouldIncrease
        ? ((item.pricePerQty - item.discountPerQty) *
            (item.totalQuantity + 1) *
            userCountryVat) /
          100
        : ((item.pricePerQty - item.discountPerQty) *
            (item.totalQuantity - 1) *
            userCountryVat) /
          100,
      discount: shouldIncrease
        ? item.discountPerQty * (item.totalQuantity + 1)
        : item.discountPerQty * (item.totalQuantity - 1),
    };

    if (item.itemMedia === 'Book') {
      data = {
        ...data,
        bookId: item.itemId,
      };
    } else if (item.itemMedia === 'Audios') {
      data = {
        ...data,
        audioId: item.itemId,
      };
    } else {
      data = {
        ...data,
        videoId: item.itemId,
      };
    }

    try {
      await http.putRequest(path, data, config);

      if (shouldIncrease) {
        dispatch({
          type: ActionTypes.INCREASE_CART_ITEM_QUANTITY,
          payload: item.basketId,
        });
      } else {
        dispatch({
          type: ActionTypes.DECREASE_CART_ITEM_QUANTITY,
          payload: item.basketId,
        });
      }
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
    }
  };

export const deleteAllItemsFromCart =
  (token: string) => async (dispatch: Dispatch<ICartAction>) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const path = `${envVariables.apiBaseUrl}/orderbaskets/removeall`;

    try {
      await http.deleteRequest(path, config);

      dispatch({
        type: ActionTypes.REMOVE_ALL_CART_ITEMS,
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
    }
  };

export const addItemToCart =
  (
    token: string,
    content: IBook | IAudio | IVideo | IFeatureItem,
    amount: number,
    discount: number,
    userVat: number
  ) =>
  async (dispatch: Dispatch<ICartAction | IAlertAction>) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({
      type: ActionTypes.ADD_ITEM_TO_CART_IN_PROGRESS,
    });
    const path = `${envVariables.apiBaseUrl}/orderbaskets`;

    let data: any = {
      qty: 1,
      amount,
      point: content.token,
      isEContent: content.isEContent,
      vat: ((amount - discount) * userVat) / 100,
      discount,
    };

    if (content.media === 'Book') {
      data = {
        ...data,
        bookId: content.id,
      };
    } else if (content.media === 'Video') {
      data = {
        ...data,
        videoId: content.id,
      };
    } else {
      data = {
        ...data,
        audioId: content.id,
      };
    }

    try {
      await http.postRequest(path, data, config);
      dispatch({
        type: ActionTypes.ADD_ITEM_TO_CART_SUCCESS,
      });
    } catch (err) {
      console.log(err.response ? err.response.data : err.message);
      dispatch({
        type: ActionTypes.ADD_ITEM_TO_CART_FAILED,
      });
      dispatch({
        type: ActionTypes.SET_ALERT,
        payload: {
          alertType: AlertTypeEnum.BackendAlert,
          message: error.formatMessage(err) as string,
        },
      });
    }
  };

export const resetAddToCartSuccess =
  () => async (dispatch: Dispatch<ICartAction>) => {
    dispatch({
      type: ActionTypes.RESET_ADD_TO_CART_SUCCESS,
    });
  };
