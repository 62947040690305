import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Avatar } from '@material-ui/core';
import { IUser } from '../../models';
import './profileAvatarCard.scss';
import { stringUtil } from '../../utility';

interface IProfileAvatarCardProps {
  user: IUser;
  onChangePasswordButtonClick: (isOpen: boolean) => void;
}

const ProfileAvatarCard: React.FC<IProfileAvatarCardProps> = ({
  user,
  onChangePasswordButtonClick,
}) => {
  return (
    <Card className="text-center profileAvatarCard">
      <div className="profileAvatarCard__avatarContainer">
        <Avatar className="profileAvatarCard__avatar">
          {stringUtil.getInitialsFromFullName(user.firstName, user.lastName)}
        </Avatar>
      </div>

      <Card.Body>
        <Card.Title className="text-center profileAvatarCard__name">
          {`${user.firstName} ${user.lastName}`}
        </Card.Title>
        <div>Token: {user.token}</div>
        <Card.Text>{user.subscription} member</Card.Text>
        <Card.Text>{user.email}</Card.Text>
        <Button
          onClick={() => onChangePasswordButtonClick(true)}
          className="profileAvatarCard__button"
        >
          Change Password
        </Button>
      </Card.Body>
    </Card>
  );
};

export default ProfileAvatarCard;
