import React, { useEffect } from 'react';
import { AlertTypeEnum } from '../../customTypes';
import { useAlert, useLibrary, useRouter, useStoreState } from '../../hooks';
import { useMedia } from '../../hooks';
import { BookRibbon, AudioRibbon, VideoRibbon, Banner } from '../common';
import CustomAlert from '../common/CustomAlert';
import { FeatureRibbon } from '../common/FeatureRibbon';
import MediaAlert from '../common/MediaAlert';
import MediaSnackBar from '../common/MediaSnackBar';
import './index.scss';

const Home: React.FC = () => {
  const {
    media: { ribbonBooks, ribbonAudios, ribbonVideos, featured },
    auth: { userCurrency },
    alert: { type: alertType, isOpen: isAlertOpen },
  } = useStoreState();

  const { selectMedia, addMediaToCart, addMediaToLibrary } = useMedia();
  const { closeAlert } = useAlert();
  const { navigateTo } = useRouter();

  return (
    <div className="home">
      <div className="home__banner">
        <Banner
          title="Welcome to Cholishnu Web"
          subtitle="At Cholishnu, we are building an online platform with four modules:
          books, audio, video and events."
        />
      </div>
      {featured && featured.length > 0 && (
        <div className="home__ribbonHeader">
          <h3>Featured</h3>
          <hr className="home__divider" />
        </div>
      )}

      {featured && featured.length > 0 && (
        <div style={{ marginBottom: 30 }} className="home__ribbon">
          <FeatureRibbon
            onAddToLibrary={addMediaToLibrary}
            onAddToCart={addMediaToCart}
            onFeatureSelect={selectMedia}
            features={featured}
            currency={userCurrency}
          />
        </div>
      )}

      {ribbonBooks && ribbonBooks.length > 0 && (
        <div className="home__ribbonHeader">
          <h3>Books</h3>
          <hr className="home__divider" />
        </div>
      )}

      {ribbonBooks && ribbonBooks.length > 0 && (
        <div className="home__ribbon">
          <BookRibbon
            onDeleteFromLibrary={() => {}}
            onAddToLibrary={addMediaToLibrary}
            onAddToCart={addMediaToCart}
            onBookSelect={selectMedia}
            books={ribbonBooks}
            currency={userCurrency}
          />
        </div>
      )}

      {ribbonAudios && ribbonAudios.length > 0 && (
        <div className="home__ribbonHeader">
          <h3>Audio</h3>
          <hr className="home__divider" />
        </div>
      )}

      {ribbonAudios && ribbonAudios.length > 0 && (
        <div className="home__ribbon">
          <AudioRibbon
            onDeleteFromLibrary={() => {}}
            onAddToLibrary={addMediaToLibrary}
            onAddToCart={addMediaToCart}
            onAudioSelect={selectMedia}
            audio={ribbonAudios}
            currency={userCurrency}
          />
        </div>
      )}

      {ribbonVideos && ribbonVideos.length > 0 && (
        <div className="home__ribbonHeader">
          <h3>Video</h3>
          <hr className="home__divider" />
        </div>
      )}

      {ribbonVideos && ribbonVideos.length > 0 && (
        <div className="home__ribbon">
          <VideoRibbon
            onDeleteFromLibrary={() => {}}
            onAddToLibrary={addMediaToLibrary}
            onAddToCart={addMediaToCart}
            onVideoSelect={selectMedia}
            video={ribbonVideos}
            currency={userCurrency}
          />
        </div>
      )}

      <MediaAlert />
      <MediaSnackBar />
    </div>
  );
};

export default Home;
